import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/image/logo_dark.svg";
import { getSiteSettingUser } from "../api/user";

function Footer() {

  const [data, setData] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSiteSettingUser()
      setData(data?.result)
    }
    fetchData()
  }, [])
  return (
    <footer className="footer_section">
      <Container>
        <div className="footer_top">
          <img src={require("../assets/image/darklogo.png")} alt="Icon" />
          <ul className="quick_links">
            <li>Quick Links :</li>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/buy-crypto">Buy</Link>
            </li>
            <li>
              <Link to="/sell-crypto">Sell</Link>
            </li>
            <li>
              <Link to="/">Help</Link>
            </li>
            <li>
              <Link to="/">Learn</Link>
            </li>
          </ul>
        </div>
        <div className="footer_bottom">
          <div className="footer_bottom_left">
            <p>
              Copyright © 2024 <a href="/">Neco Smart</a>. All rights reserved.
            </p>
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms of Service</Link>
              </li>
            </ul>
          </div>
          {
            data &&
            <ul className="social_links">
              <li>
                <a href={data.telegramLink} target="_blank">
                  <i class="fa-solid fa-paper-plane"></i>
                </a>
              </li>
              <li>
                <a href={data.facebookLink} target="_blank">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href={data.twitterUrl} target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
              </li>
              <li>
                <a href={data.instaLink} target="_blank">
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </li>
              {/* <li>
                <a href={data.telegramLink} target="_blank">
                  <i class="fa-brands fa-whatsapp"></i>
                </a>
              </li> */}
              <li>
                <a href={data.youtubeLink} target="_blank">
                  <i class="fa-brands fa-youtube"></i>
                </a>
              </li>
            </ul>
          }
        </div>
      </Container>
    </footer>
  );
}
export default Footer;
