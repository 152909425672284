import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js"
import humanize from "humanize-plus"
import React, { useContext, useEffect, useState } from "react"
import ApexCharts from "react-apexcharts"
import { Table } from "react-bootstrap"
import { getAllCandleData } from "../api/wallet"
import Footer from "../component/footer"
import NavbarInner from "../component/navbar-inner"
import Sidebar from "../component/sidebar"
import ReactApexChart from "react-apexcharts"
import { useNavigate } from "react-router-dom"
import { calculateMarkup, getPriceData } from "../api/currency"
import { SocketContext } from "../context/SocketContext"
import { useSelector } from "react-redux"
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend)

const currentData = (coin, cadData) => {
  const data = {
    candleData:
      coin !== "USDC"
        ? cadData?.candleData.map(item => item.high)
        : [
          1.38, 1.38, 1.39, 1.38, 1.38, 1.37, 1.38, 1.38, 1.38, 1.38, 1.38, 1.39, 1.38,
          1.37, 1.38, 1.38,
        ],
  }

  return data
}

export default function (props) {
  const [candleData, setCandleData] = useState([])
  const [priceData, setPriceData] = useState([])

  const currencyData = useSelector((state) => state.currency)

  const navigate = useNavigate()

  const fetchCandleData = async () => {
    try {
      const { success, result } = await getAllCandleData()
      if (success) {
        setCandleData(result.data)
      }
    } catch (error) {
      console.error("Error fetching candle data", error)
    }
  }
  const fetchPriceData = async () => {
    try {
      const { success, result } = await getPriceData()
      if (success) {
        setPriceData(result.data)
      }
    } catch (error) {
      console.error("Error fetching candle data", error)
    }
  }

  useEffect(() => {
    fetchCandleData()
    fetchPriceData()
  }, [])

  const options = color => {
    return {
      chart: {
        type: "area",
        height: 90,
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: [color],
      },
      fill: {
        // opacity: 0.3,
        type: "gradient",

        gradient: {
          shade: "light",
          shadeIntensity: 0.5,
          opacityFrom: 1,
          opacityTo: 0,
          colorStops: [
            {
              offset: 0,
              color: color,
              opacity: 0.3,
            },
            {
              offset: 100,
              color: color,
              opacity: 0,
            },
          ],
        },
      },
      tooltip: {
        enabled: true,
      },
      colors: [color],
      // colors: differences.map(getColor),
    }
  }
  const series = item => {
    return [
      {
        name: "Market Price",
        data: item,
      },
    ]
  }



  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!socket) return;
    socket.on("marketPrice", async (data) => {
      // console.log("marketPrice",result);
      await fetchPriceData()
    })
    return () => {
      socket.off("marketPrice");
    };
  }, [priceData])

  const currentmarkupPercentage = (item) => {
    if (currencyData.length > 0) {
      const data = currencyData.find((data) => data.coin === item);
      return data ? data.markupPercentage : 0
    }
  }

  return (
    <>
      <NavbarInner />
      <div className="dashpage_wrapper">
        <Sidebar />
        <main className="main_inner_content">
          <div className="dash_section">
            <h2 className="dash_title">Live Rate</h2>
            <div className="height_eq_div">
              <div className="table_dash_card nav_history">
                <div className="table_dash_card1">
                  <Table responsive className="primary_table table-hover">
                    <thead>
                      <tr>
                        <th>Crypto</th>
                        <th>Price (CAD)</th>
                        <th>24h Volume</th>
                        <th>Markets</th>
                        <th>Markets Cap</th>
                        <th>Total Volume</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(priceData) &&
                        priceData.length > 0 &&
                        priceData.map((item, index) => {
                          const markupPercentage = currentmarkupPercentage(item.baseSymbol);
                          console.log(item,"item");
                          
                          return (
                            <tr key={index}>
                              <td>{item.baseSymbol} / CAD</td>
                              <td>${calculateMarkup(item.convertPrice_cad, markupPercentage)}</td>
                              <td>$ {humanize.compactInteger(item["24h_cad"])}</td>

                              <td>
                                <div
                                  className="chart-container"
                                  style={{
                                    height: "80px",
                                    width: "220px",
                                    color: "black",
                                  }}
                                >
                                  <ReactApexChart
                                    col
                                    options={options(
                                      item.difference_cad < 0 ? "red" : "green",
                                    )}
                                    series={series(item.candleData)}
                                    type="area"
                                    height={90}
                                  />
                                </div>
                              </td>
                              <td>${humanize.compactInteger(item.marketCap_cad, 1)}</td>
                              <td>${humanize.compactInteger(item.totalVolume_cad)}</td>
                              <td>
                                <button
                                  className="border_btn border_btn1"
                                  onClick={() => navigate("/buy-crypto", { state: { coin: item.baseSymbol } })}
                                >
                                  <span>Trade</span>
                                  <img
                                    src={require("../assets/image/btn-arrow.png")}
                                    className="img-fluid ms-2"
                                  />
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </>
  )
}
