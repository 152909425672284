// import package
// import crypto from "crypto"
import CryptoJS from "crypto-js"

import config from "../config"

// export const randomByte = async byte => {
//   try {
//     return await crypto.randomBytes(byte).toString("hex")
//   } catch (err) {
//     return ""
//   }
// }

// export const createHash = algo => {
//   return crypto.createHash(algo)
// }

// export const createHmac = (algo, secretKey) => {
//   return crypto.createHmac(algo, secretKey)
// }

// export const generateOTP = () => {
//   const randomBytes = crypto.randomBytes(3) // Use 3 bytes for a 24-bit integer
//   const randomNumber = randomBytes.readUIntBE(0, 3) % 1000000 // Ensure a 6-digit number
//   return randomNumber.toString().padStart(6, "0") // Pad with zeros if needed
// }

export const encryptObject = encryptValue => {
  try {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(encryptValue),
      config.CRYPTO_SECRET_KEY,
    ).toString()
    return ciphertext
  } catch (err) {
    console.log("erreeeeeeeeeee: ", err)

    return ""
  }
}

export const decryptObject = decryptValue => {
  try {
    let bytes = CryptoJS.AES.decrypt(decryptValue, config.CRYPTO_SECRET_KEY)
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  } catch (err) {
    return ""
  }
}
