import $ from "jquery"
import React, { useEffect } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { removeAuthorization } from "../config/axios"
import { removeAuthToken } from "../utils/cookie"
import { toast } from "../lib/toast"
import { useDispatch } from "react-redux"
import { resetAccount } from "../redux/slices/account"

function Sidebar() {
  useEffect(() => {
    loadScript()
  }, [])

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky")
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop()
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up")
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned")
      500 <= e ? t.addClass("navbar-sticky--on") : t.removeClass("navbar-sticky--on")
    })
  }

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const handleLogout = () => {
    removeAuthorization()
    removeAuthToken()
    toast({ message: "Logout successfully", type: "success" }, dispatch)
    dispatch(resetAccount())
    navigate("/login")
  }

  return (
    <nav className="sidebar navbar navbar-expand-xl">
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        {/* <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
        </div> */}
        <div className="offcanvas-body">
          <div className="text-end">
            <div className="text-end close_end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="sidebar_navlist">
            <h6 className="sidebar_heading">Menu</h6>
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/funding">
                  <img
                    src={require("../assets/image/menu_icons/menu_icon_01.png")}
                    alt="Icon"
                  />
                  <span>Funding</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/buy-crypto">
                  <img
                    src={require("../assets/image/menu_icons/menu_icon_02.png")}
                    alt="Icon"
                  />
                  <span>Buy Crypto</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/sell-crypto">
                  <img
                    src={require("../assets/image/menu_icons/menu_icon_03.png")}
                    alt="Icon"
                  />
                  <span>Sell Crypto</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/live-rate">
                  <img
                    src={require("../assets/image/menu_icons/menu_icon_04.png")}
                    alt="Icon"
                  />
                  <span>Live Rate</span>
                </NavLink>
              </li>
            </ul>

            <h6 className="sidebar_heading">Settings</h6>
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/account">
                  <img
                    src={require("../assets/image/menu_icons/menu_icon_05.png")}
                    alt="Icon"
                  />
                  <span>Account</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/security">
                  <img
                    src={require("../assets/image/menu_icons/menu_icon_06.png")}
                    alt="Icon"
                  />
                  <span>Security</span>
                </NavLink>
              </li>
            </ul>
            <h6 className="sidebar_heading">More</h6>
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/blog">
                  <img
                    src={require("../assets/image/menu_icons/menu_icon_08.png")}
                    alt="Icon"
                  />
                  <span>Blog</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to="/support">
                  <img
                    src={require("../assets/image/menu_icons/menu_icon_09.png")}
                    alt="Icon"
                  />
                  <span>Support</span>
                </NavLink>
              </li>
            </ul>

            <ul className="nav flex-column green-shade">
              <li className="nav-item">
                <NavLink className="nav-link" onClick={handleLogout}>
                  <img
                    src={require("../assets/image/menu_icons/menu_icon_10.png")}
                    alt="Icon"
                  />
                  <span>Log Out</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}
export default Sidebar
