import React from "react"

import Footer from "../component/footer"
import MyNavbar from "../component/navbar"

import RegisterForm from "../component/Auth/Register/Form"

export default function Register(props) {
  return (
    <>
      <RegisterForm />
    </>
  )
}
