import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Offcanvas from "react-bootstrap/Offcanvas"
import { Link, NavLink } from "react-router-dom"
import logo from "../assets/image/logo.svg"
import $ from "jquery"
import { getCookie } from "../utils/cookie"
import isLogin from "../lib/isLogin"

function MyNavbar() {
  const [isAuth, setIsAuth] = useState(isLogin())

  useEffect(() => {
    loadScript()
  }, [])

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky")
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop()
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up")
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned")
      500 <= e ? t.addClass("navbar-sticky--on") : t.removeClass("navbar-sticky--on")
    })
  }

  return (
    <Navbar expand="xl" className="navbar-main navbar-sticky" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-xl`}
          aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
          placement="end"
          className="nav-off"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {isAuth ? (
              <Nav className="mx-auto nav_middle">
                <NavLink className="nav-link" to="/home">
                  Home
                </NavLink>
                <NavLink className="nav-link" to="/buy-crypto">
                  Buy
                </NavLink>
                <NavLink className="nav-link" to="/sell-crypto">
                  Sell
                </NavLink>
                {/* <NavLink className="nav-link" to="/help">
                Help
              </NavLink> */}
              </Nav>
            ) : (
              <Nav className="mx-auto nav_middle"></Nav>
            )}
            <Nav className="navbar_end">
              {!isAuth && (
                <NavLink className="primary-btn" to="/login">
                  Sign In
                </NavLink>
              )}
              <NavLink className="primary-btn" to="/register">
                Get Started
                <img
                  src={require("../assets/image/btn-arrow.png")}
                  className="img-fluid"
                />
              </NavLink>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}
export default MyNavbar
