import { setAccount, setProfilePicture } from "../redux/slices/account"
import { handleResponse } from "./Axios"
import axios from "../config/axios"
import { setupCurrency } from "../redux/slices/currency"


export const retrieveUserProfile = async () => {
  try {
    const response = await axios({
      url: "/user/profile",
      method: "GET",
    })

    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const getAccessToken = async data => {
  try {
    const response = await axios({
      url: "/user/accessToken",
      method: "POST",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const emailMe = async () => {
  try {
    const response = await axios({
      url: "/user/email-me",
      method: "GET",
    })

    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}

export const fetchUserProfile = async dispatch => {
  try {
    console.log("api calling");
    
    const response = await axios({
      url: "/user/profile",
      method: "GET",
    })
    console.log(response.data.result,"result");
    
    dispatch(setAccount(response.data.result))
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const sampleSocket = async () => {
  try {
    const response = await axios({
      url: "/user/sample-socket",
      method: "GET",
    })
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const getUserSecret = async () => {
  try {
    const response = await axios({
      url: "/user/user-secret",
      method: "GET",
    })
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const updateBankDetails = async data => {
  try {
    const response = await axios({
      url: "/user/bank-details",
      method: "PUT",
      data,
    })
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const updateEmail = async (data, dispatch) => {
  try {
    const response = await axios({
      url: "/user/update-mail",
      method: "PUT",
      data,
    })
    dispatch(setAccount({ email: response.data.email }))
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const getBankDetails = async () => {
  try {
    const response = await axios({
      url: "/user/bank-details",
      method: "GET",
    })
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}

export const updateAccountDetails = async data => {
  try {
    const response = await axios({
      url: "/v1/user/update-account",
      method: "POST",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}
export const updateProfilePicture = async (data, dispatch) => {
  try {
    const response = await axios({
      url: "/user/update-profile-pic",
      method: "PUT",
      data,
    })

    dispatch(setProfilePicture(response.data.profileImage))

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const updatePassword = async data => {
  try {
    console.log("responseData", data)

    const response = await axios({
      url: "/user/changePassword",
      method: "POST",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const getLoginHistory = async data => {
  try {
    const response = await axios({
      url: "/v1/user/login-history",
      method: "GET",
      params: data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const get2FA = async () => {
  try {
    const response = await axios({
      url: "/user/two-factor-auth",
      method: "GET",
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const enable2FA = async data => {
  try {
    const response = await axios({
      url: "/user/two-factor-auth",
      method: "POST",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const disable2FA = async data => {
  try {
    const response = await axios({
      url: "/user/two-factor-auth",
      method: "PUT",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const forgetPassword = async data => {
  try {
    const response = await axios({
      url: "/auth/forgotPassword",
      method: "POST",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}
export const otpVerification = async data => {
  try {
    const response = await axios({
      url: "/user/sentOtp",
      method: "POST",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}
export const resetPassword = async data => {
  try {
    const response = await axios({
      url: "/auth/resetPassword",
      method: "post",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const retrieveUserWallet = async () => {
  try {
    const response = await axios({
      url: "/v1/wallet/user-wallet",
      method: "GET",
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const retrieveCurrency = async () => {
  try {
    const response = await axios({
      url: "/v1/currency/getCurrencyUser",
      method: "GET",
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const getCurrencyList = async (dispatch) => {
  try {
    const response = await axios({
      url: "/user/getCurrencyUser",
      method: "GET",
    })
    dispatch(setupCurrency(response.data.result))
    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const getActiveCurrencyList = async () => {
  try {
    const response = await axios({
      url: "/user/getActiveCurrencyUser",
      method: "GET",
    })
    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const getSiteSettingUser = async () => {
  try {
    const response = await axios({
      url: "/user/getSiteSettingUser",
      method: "GET",
    })
    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}
export const updateCompanyDetails = async data => {
  try {
    const response = await axios({
      url: "/v1/user/account-details",
      method: "PUT",
      data,
    })
    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}
export const addBankDetails = async data => {
  try {
    const response = await axios({
      url: "/v1/user/bank-details",
      method: "post",
      data,
    })
    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const getDashboard = async () => {
  try {
    const response = await axios({
      url: "/v1/user/dashboard",
      method: "GET",
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const getSupportCategory = async () => {
  try {
    const response = await axios({
      url: "/user/support-category",
      method: "GET",
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const postSupportTicket = async data => {
  try {
    const response = await axios({
      url: "/user/support-ticket",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}
export const closeTicketApi = async data => {
  try {
    const response = await axios({
      url: "/user/support-ticket",
      method: "PUT",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const getSupportTickets = async () => {
  try {
    const response = await axios({
      url: "/user/support-ticket",
      method: "GET",
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}

export const postSupportTicketReply = async data => {
  try {
    const response = await axios({
      url: `/user/ticket-reply`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}
export const getCmsContent = async identifier => {
  try {
    const response = await axios({
      url: `/user/cms/${identifier}`,
      method: "GET",
    })

    return handleResponse(response, "success")
  } catch (error) {
    return handleResponse(error, "error")
  }
}
