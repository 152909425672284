import React, { useEffect, useState } from "react"
import { Form, InputGroup } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { getUserSecret } from "../../../api/user"
import { toast } from "../../../lib/toast"
import { useDispatch } from "react-redux"
import { CopyToClipboard } from "react-copy-to-clipboard"

const InstructionModal = ({ show, handleClose }) => {
  const dispatch = useDispatch()

  const [secret, setSecret] = useState({
    seqQuiz: "",
    seqAns: "",
    recipientUserName: "",
    recipientUserEmail: "",
  })

  const fetchUserSecret = async () => {
    const { success, result } = await getUserSecret()
    console.log("getUserSecretsuccess", success)
    if (success) {
      setSecret({
        seqQuiz: result.data.seqQuiz,
        seqAns: result.data.seqAns,
        recipientUserName: result.data.adminCredentials.userName,
        recipientUserEmail: result.data.adminCredentials.email,
      })
    }
    console.log("result", result)
  }

  useEffect(() => {
    fetchUserSecret()
  }, [])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
      className="order_confrim_model"
    >
      <Modal.Header closeButton>
        <Modal.Title>Interac-e-Transfer Instructions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The Interac-e-Transfer must be sent from your personal bank account bearing your
          first and surname.
        </p>
        <p>
          Upon receiving your interac-e-Transfer,it will be immediately accepted with your
          NecoSmart account balance funded momentarily after.
        </p>
        <p>
          Once the funds have been added to your account balance, you will receive an
          email notification advising you to create an order and instantly complete your
          transaction for cryptocurrency
        </p>

        <Form onSubmit={handleClose}>
          <div className="input_group_model">
            <Form.Label>Recipient Name</Form.Label>
            <InputGroup className="mb-3" controlId="recipientName">
              <Form.Control
                type="text"
                name="recipientName"
                placeholder="Recipient Name"
                className="primary_input"
                value={secret.recipientUserName}
                disabled
              />
              <InputGroup.Text id="basic-addon2">
                <CopyToClipboard
                  text={secret && secret.recipientUserName}
                  onCopy={() => {
                    toast(
                      {
                        message: "Copied",
                        type: "success",
                      },
                      dispatch,
                    )
                  }}
                >
                  <a href="#">
                    <img
                      src={require("../../../assets/image/copy-Filled.png")}
                      className="img-fluid"
                      alt="copy-Filled"
                    />
                  </a>
                </CopyToClipboard>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="input_group_model">
            <Form.Label>Recipient Email</Form.Label>
            <InputGroup className="mb-3" controlId="recipientEmail">
              <Form.Control
                type="email"
                name="recipientEmail"
                placeholder="Recipient Email"
                value={secret.recipientUserEmail}
                className="primary_input"
                disabled
              />
              <InputGroup.Text id="basic-addon2">
                <CopyToClipboard
                  text={secret && secret.recipientUserEmail}
                  onCopy={() => {
                    toast(
                      {
                        message: "Copied",
                        type: "success",
                      },
                      dispatch,
                    )
                  }}
                >
                  <a href="#">
                    <img
                      src={require("../../../assets/image/copy-Filled.png")}
                      className="img-fluid"
                      alt="copy-Filled"
                    />
                  </a>
                </CopyToClipboard>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="input_group_model">
            <Form.Label>Secret Question</Form.Label>

            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                name="seqQuiz"
                value={secret.seqQuiz}
                placeholder="Secret Question"
                className="primary_input"
                style={{ paddingLeft: "20px" }}
                disabled
              />
              <InputGroup.Text id="basic-addon2">
                <CopyToClipboard
                  text={secret && secret.seqQuiz}
                  onCopy={() => {
                    toast(
                      {
                        message: "Copied",
                        type: "success",
                      },
                      dispatch,
                    )
                  }}
                >
                  <a href="#">
                    <img
                      src={require("../../../assets/image/copy-Filled.png")}
                      className="img-fluid"
                      alt="copy-Filled"
                    />
                  </a>
                </CopyToClipboard>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="input_group_model">
            <Form.Label>Secret Answer</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                name="seqAns"
                value={secret.seqAns}
                placeholder="Secret Answer"
                className="primary_input"
                style={{ paddingLeft: "20px" }}
                disabled
              />

              <InputGroup.Text id="basic-addon2">
                <CopyToClipboard
                  text={secret && secret.seqAns}
                  onCopy={() => {
                    toast(
                      {
                        message: "Copied",
                        type: "success",
                      },
                      dispatch,
                    )
                  }}
                >
                  <a href="#">
                    <img
                      src={require("../../../assets/image/copy-Filled.png")}
                      className="img-fluid"
                      alt="copy-Filled"
                    />
                  </a>
                </CopyToClipboard>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="model_btn">
            <Button type="submit" className="">
              Done
              <img
                src={require("../../../assets/image/arrow_orange.png")}
                className="img-fluid orange_arrow"
              />
              <img
                src={require("../../../assets/image/btn-arrow.png")}
                className="img-fluid white_arrow"
              />
            </Button>
          </div>
        </Form>
        <p>
          <span className="text-danger">** IMPORTANT **</span> Ensure you copy and paste
          the security question and answer exactly as displayed wiTytryrtytrythout adding
          any addition
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default InstructionModal
