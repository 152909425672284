import React, { useState } from "react"

import LoginForm from "../component/Auth/Login/Form"
import Footer from "../component/footer"
import MyNavbar from "../component/navbar"

export default function Login() {
  const [visibleLoginDiv, setVisibleLoginDiv] = useState("DeskLoginDiv")

  const DeskLogin = () => {
    setVisibleLoginDiv("DeskLoginDiv")
  }
  const AppLogin = () => {
    setVisibleLoginDiv("AppLoginDiv")
  }

  return (
    <>
      <LoginForm
        AppLogin={AppLogin}
        visibleLoginDiv={visibleLoginDiv}
        DeskLogin={DeskLogin}
      />
    </>
  )
}
