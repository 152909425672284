import { getAuthToken, getCookie } from "../utils/cookie"

const isLogin = () => {
  if (
    getAuthToken() &&
    getCookie("loggedin") &&
    getCookie("loggedin").toString() == "true"
  ) {
    return true
  }
  return false
}

export default isLogin
