import React, { useRef, useEffect, useState, useMemo, useContext } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import MyNavbar from "../component/navbar"
import Accordion from "react-bootstrap/Accordion"
import Footer from "../component/footer"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import AOS from "aos"
import "aos/dist/aos.css"
import { getPriceCnvData } from "../api/currency"
import ReactApexChart from "react-apexcharts"
import { SocketContext } from "../context/SocketContext"

const calculateDifferences = data => {
  return data.map((value, index) => {
    if (index === 0) return 0
    return value - data[index - 1]
  })
}
const options = (chartData, coin, color) => {
  const differences = calculateDifferences(chartData)

  return {
    chart: {
      type: "area",
      height: 90,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: [color],
    },
    fill: {
      // opacity: 0.3,
      type: "gradient",

      gradient: {
        shade: "light",
        shadeIntensity: 0.5,
        opacityFrom: 1,
        opacityTo: 0,
        colorStops: [
          {
            offset: 0,
            color: color,
            opacity: 0.3,
          },
          {
            offset: 100,
            color: color,
            opacity: 0,
          },
        ],
      },
    },
    tooltip: {
      enabled: true,
    },
    colors: [color],
    // colors: differences.map(getColor),
  }
}

function Home() {
  useEffect(() => {
    AOS.init()
  }, [])

  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [priceData, setPriceData] = useState([])
  const [cryptoData, setCryptoData] = useState({})

  const mainSlider = useRef()
  const navSlider = useRef()

  const getPriceCnvDataApiCall = async () => {
    try {
      const { success, result } = await getPriceCnvData()
      if (success) {
        console.log("priceData_result", result)
        setPriceData(result.data)
        // setHighData(currentData("BTC"))
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  const currentData = coin => {
    const coinData = priceData.find(item => item.baseSymbol === coin)

    //  if (!coinData) {
    //    return null
    //  }

    const cadData = coinData?.convertData.find(i => i.convertSymbol === "CAD")
    const usdData = coinData?.convertData.find(i => i.convertSymbol === "USD")

    //  if (!cadData || !usdData) {
    //    return null
    //  }

    const difference_1 = cadData?.convertPrice - cadData?.yesterdayPrice
    const difference_2 = usdData?.convertPrice - usdData?.yesterdayPrice

    const percent_1 = Math.abs(difference_1) / cadData?.yesterdayPrice
    const percent_2 = Math.abs(difference_2) / usdData?.yesterdayPrice

    const data = {
      difference_1,
      difference_2,
      percent_1,
      percent_2,
      convertPriceUSD: usdData?.convertPrice,
      convertPriceCAD: cadData?.convertPrice,
      candleData:
        coin !== "USDC"
          ? cadData?.candleData.map(item => item.high)
          : [
            1.38, 1.38, 1.39, 1.38, 1.38, 1.37, 1.38, 1.38, 1.38, 1.38, 1.38, 1.39,
            1.38, 1.37, 1.38, 1.38,
          ],
    }

    // console.log(data,"data");

    return data
  }

  const series = (coin, getData) => {
    return [
      {
        name: "Market Price",
        data: getData(coin).candleData,
      },
    ]
  }
  useEffect(() => {
    setNav1(mainSlider.current)
    setNav2(navSlider.current)
    getPriceCnvDataApiCall()
  }, [])

  const mainSettings = useMemo(() => {
    return {
      asNavFor: nav2,
      ref: slider => (mainSlider.current = slider),
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
    }
  }, [])

  const navSettings = useMemo(() => {
    return {
      asNavFor: nav1,
      ref: slider => (navSlider.current = slider),
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      centerMode: false,
      focusOnSelect: true,
      infinite: false,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            arrows: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            arrows: true,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            arrows: true,
          },
        },
      ],
    }
  }, [])

  const { socket } = useContext(SocketContext);

  const [marketPrice, setMarketPrice] = useState()

  useEffect(() => {
    if (!socket) return;

    socket.on("marketPrice", async (data) => {
      // console.log("marketPrice",result);
      await getPriceCnvDataApiCall()
    })
    return () => {
      socket.off("marketPrice");
    };
  }, [priceData])

  // useEffect(() => {
  //   const { success, result } = await getPriceCnvData()
  //   if (success) {
  //     console.log("priceData_result", result)
  //     setPriceData(result.data)
  //     // setHighData(currentData("BTC"))
  //   }
  // },[priceData])

  console.log(marketPrice, "data");


  return (
    <>
      <MyNavbar />
      <section className="banner-sec">
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="banner-sec-head">
                <h1 data-aos="fade-up" data-aos-duration="2000">
                  The crypto experience you Deserve: Buy, Sell with Confidence.
                </h1>
                <p data-aos="fade-up" data-aos-duration="2000">
                  Enjoy a user-friendly experience with access to a wide range of
                  cryptocurrencies and competitive rates.
                </p>
                <div data-aos="fade-up" data-aos-duration="2000">
                  <Link className="primary-btn" to="/funding">
                    Get Started
                    <img
                      src={require("../assets/image/btn-arrow.png")}
                      className="img-fluid"
                    />
                  </Link>

                  <Link className="secondary-link">
                    Learn more
                    <img
                      src={require("../assets/image/btn-arrow-1.png")}
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="happy-client" data-aos="fade-up" data-aos-duration="2000">
                  <h3>50K+</h3>
                  <p>
                    Of happy<br></br> Uers
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="6" className="ban-sec-img">
              <a href="#">
                <img
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  src={require("../assets/image/sec-ban.png")}
                  className="img-fluid "
                />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <main className="landing_main">
        <section className="banner-sec-2">
          <Container>
            <Row>
              <Col lg="6">
                <h2 data-aos="fade-up" data-aos-duration="2000">
                  Explore crypto like Bitcoin, Ethereum, and USDC
                </h2>
                <p data-aos="fade-up" data-aos-duration="2000">
                  Simply and securely buy, sell, and manage hundreds of cryptocurrencies.
                </p>
                <div data-aos="fade-up" data-aos-duration="2000">
                  <Link className="primary-btn" to={"/buy-crypto"}>
                    Buy crypto
                    <img
                      src={require("../assets/image/btn-arrow.png")}
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </Col>
              {
                priceData &&
                <Col lg="6">
                  {console.log("data",priceData)}
                  <ul
                    class="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        BTC
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        ETH
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        USDC
                      </button>
                    </li>
                  </ul>
                  <div
                    class="tab-content mt-4"
                    id="pills-tabContent"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="crypto_card orange_card">
                        <div className="crypto_card_top">
                          <div className="crypto_card_top_left">
                            <img
                              src={require("../assets/image/btc.png")}
                              className="img-fluid"
                            />

                            <span>BTC</span>
                          </div>
                          <div className="crypto_card_top_right">
                            <div className="crypto_card_badge crypto_card_badge_green">
                              <img
                                src={require(`../assets/image/${currentData("BTC").difference_1 < 0
                                    ? "downtrend_icon"
                                    : "uptrend_icon"
                                  }.png`)}
                                className="img-fluid"
                              />
                              <h5
                                className={`${currentData("BTC").difference_1 < 0
                                    ? "text_red"
                                    : "text_green"
                                  }`}
                              >
                                {currentData("BTC")?.difference_1?.toFixed(2)}{" "}
                                <span>({currentData("BTC")?.percent_1?.toFixed(2)} %)</span>
                              </h5>
                            </div>
                            <h6>24 h</h6>
                          </div>
                        </div>
                        <div className="crypto_card_bottom">
                          <div className="crypto_card_price">
                            <h3>
                              {currentData("BTC").convertPriceCAD?.toFixed(2)}{" "}
                              <span>CAD</span>
                            </h3>
                            <h3>
                              {currentData("BTC").convertPriceUSD?.toFixed(2)}{" "}
                              <span>USD</span>
                            </h3>
                          </div>
                          {/* <img
                          src={require("../assets/image/chart_01.png")}
                          className="img-fluid"
                        /> */}

                          <ReactApexChart
                            options={options(
                              priceData,
                              "BTC",
                              currentData("BTC").difference_2 < 0 ? "red" : "green",
                            )}
                            series={series("BTC", currentData)}
                            type="area"
                            height={90}
                            width={280}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="crypto_card blue_card">
                        <div className="crypto_card_top">
                          <div className="crypto_card_top_left">
                            <img
                              src={require("../assets/image/eth.png")}
                              className="img-fluid"
                            />
                            <span>ETH</span>
                          </div>
                          <div className="crypto_card_top_right">
                            <div className="crypto_card_badge crypto_card_badge_green">
                              <img
                                src={require(`../assets/image/${currentData("ETH").difference_1 < 0
                                    ? "downtrend_icon"
                                    : "uptrend_icon"
                                  }.png`)}
                                className="img-fluid"
                              />
                              <h5
                                className={`${currentData("ETH").difference_1 < 0
                                    ? "text_red"
                                    : "text_green"
                                  }`}
                              >
                                {currentData("ETH")?.difference_1?.toFixed(2)}{" "}
                                <span>({currentData("ETH")?.percent_1?.toFixed(2)} %)</span>
                              </h5>
                            </div>
                            <h6>24 h</h6>
                          </div>
                        </div>
                        <div className="crypto_card_bottom">
                          <div className="crypto_card_price">
                            <h3>
                              {currentData("ETH").convertPriceCAD?.toFixed(2)}{" "}
                              <span>CAD</span>
                            </h3>
                            <h3>
                              {currentData("ETH").convertPriceUSD?.toFixed(2)}{" "}
                              <span>USD</span>
                            </h3>
                          </div>
                          {/* <img
                          src={require("../assets/image/chart_01.png")}
                          className="img-fluid"
                        /> */}
                          <ReactApexChart
                            options={options(
                              priceData,
                              "ETH",
                              currentData("ETH").difference_1 < 0 ? "red" : "green",
                            )}
                            series={series("ETH", currentData)}
                            type="area"
                            height={90}
                            width={280}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-contact"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                    >
                      <div className="crypto_card green_card">
                        <div className="crypto_card_top">
                          <div className="crypto_card_top_left">
                            <img
                              src={require("../assets/image/usdc.png")}
                              className="img-fluid"
                            />
                            <span>USDC</span>
                          </div>
                          <div className="crypto_card_top_right">
                            <div className="crypto_card_badge crypto_card_badge_green">
                              <img
                                src={require(`../assets/image/${currentData("USDC").difference_1 < 0
                                    ? "downtrend_icon"
                                    : "uptrend_icon"
                                  }.png`)}
                                className="img-fluid"
                              />
                              <h5
                                className={`${currentData("USDC").difference_1 < 0
                                    ? "text_red"
                                    : "text_green"
                                  }`}
                              >
                                {currentData("USDC")?.difference_1?.toFixed(2)}{" "}
                                <span>
                                  ({currentData("USDC")?.percent_1?.toFixed(2)} %)
                                </span>
                              </h5>
                            </div>
                            <h6>24 h</h6>
                          </div>
                        </div>
                        <div className="crypto_card_bottom">
                          <div className="crypto_card_price">
                            <h3>
                              {currentData("USDC").convertPriceCAD?.toFixed(2)}{" "}
                              <span>CAD</span>
                            </h3>
                            <h3>
                              {currentData("USDC").convertPriceUSD?.toFixed(2)}{" "}
                              <span>USD</span>
                            </h3>
                          </div>
                          {/* <img
                          src={require("../assets/image/chart_01.png")}
                          className="img-fluid"
                        /> */}
                          <ReactApexChart
                            options={options(
                              priceData,
                              "USDC",
                              currentData("USDC").difference_1 < 0 ? "red" : "green",
                            )}
                            series={series("USDC", currentData)}
                            type="area"
                            height={90}
                            width={280}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              }
            </Row>
          </Container>
        </section>
        <section className="banner-sec-3">
          <Container>
            <h2 className="head text-center" data-aos="fade-up" data-aos-duration="2000">
              Why choose our Platform?
            </h2>
            <div className="platform-choose">
              <Row>
                <Col lg="6" data-aos="zoom-in" data-aos-duration="2000">
                  <div className="box">
                    <img
                      src={require("../assets/image/icon1.png")}
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                      data-aos-delay="300"
                      className="img-fluid"
                    />
                    <h4>You Control Your Assets</h4>
                    <p>
                      With our non-custodial platform, you retain complete control over
                      your funds. No need to trust a third party with your assets,
                      ensuring maximum security and peace of mind.
                    </p>
                  </div>
                </Col>
                <Col lg="6" data-aos="zoom-in" data-aos-duration="2000">
                  <div className="box">
                    <img
                      src={require("../assets/image/icon2.png")}
                      className="img-fluid"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                      data-aos-delay="300"
                    />
                    <h4>Privacy First</h4>
                    <p>
                      Your transactions remain private. We do not require any personal
                      information, guaranteeing your privacy and security.
                    </p>
                  </div>
                </Col>
                <Col lg="6" data-aos="zoom-in" data-aos-duration="2000">
                  <div className="box">
                    <img
                      src={require("../assets/image/icon3.png")}
                      className="img-fluid"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                      data-aos-delay="300"
                    />
                    <h4>Fast and Reliable</h4>
                    <p>
                      Experience quick and reliable transactions. Our platform ensures
                      your transactions are executed swiftly, with minimal delays.
                    </p>
                  </div>
                </Col>
                <Col lg="6" data-aos="zoom-in" data-aos-duration="2000">
                  <div className="box">
                    <img
                      src={require("../assets/image/icon4.png")}
                      className="img-fluid"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                      data-aos-delay="300"
                    />
                    <h4>Top-Notch Security</h4>
                    <p>
                      Our platform employs advanced security measures, including
                      end-to-end encryption and multi-factor authentication, to keep your
                      assets safe and secure.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className="banner-sec-4">
          <Container>
            <Row className="align-items-center">
              <Col lg="6">
                <div className="banner-sec-4-head">
                  <h2 className="head" data-aos="fade-up" data-aos-duration="2000">
                    All Canadian Banks Supported
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="2000">
                    With the ability to accept funds via Interac e-Transfer, bank wire and
                    credit card, our platform can handle all size transactions from large
                    to small — keeping speed, safety and security a top priority.
                  </p>
                  <div data-aos="fade-up" data-aos-duration="2000">
                    <Link className="primary-btn">
                      Learn more
                      <img
                        src={require("../assets/image/btn-arrow.png")}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="image-client" data-aos="fade-up" data-aos-duration="2000">
                  <img src={require("../assets/image/img1.png")} className="img-fluid" />
                  <img src={require("../assets/image/img2.png")} className="img-fluid" />
                  <img src={require("../assets/image/img3.png")} className="img-fluid" />
                  <img src={require("../assets/image/img4.png")} className="img-fluid" />
                  <img src={require("../assets/image/img5.png")} className="img-fluid" />
                  <img src={require("../assets/image/img6.png")} className="img-fluid" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="banner-sec-5">
          <Container>
            <Row className="align-items-center">
              <Col xxl={7} xl={8} lg={8} xs={10} className="mx-auto mx-xl-0">
                <h2 className="head" data-aos="fade-up" data-aos-duration="2000">
                  What our users say <br /> Hear from our satisfied users.
                </h2>
                <div data-aos="fade-up" data-aos-duration="2000">
                  <Slider {...navSettings} className="testimonial_nav_slider">
                    <div>
                      <div className="rating-profile">
                        <img
                          src={require("../assets/image/profile1.png")}
                          className="img-fluid "
                        />
                        <div>
                          <p>Sarah L.</p>
                          <ul className="ratings">
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="rating-profile">
                        <img
                          src={require("../assets/image/profile2.png")}
                          className="img-fluid "
                        />
                        <div>
                          <p>John M.</p>
                          <ul className="ratings">
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="rating-profile">
                        <img
                          src={require("../assets/image/profile3.png")}
                          className="img-fluid "
                        />
                        <div>
                          <p>Emily R.</p>
                          <ul className="ratings">
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="rating-profile">
                        <img
                          src={require("../assets/image/profile4.png")}
                          className="img-fluid "
                        />
                        <div>
                          <p>David K.</p>
                          <ul className="ratings">
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                            <li className="ratings-star">
                              <i class="fa-solid fa-star"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Slider>
                  <Slider {...mainSettings} className="testimonial_main_slider">
                    <div>
                      <h4>
                        "This platform gives me the freedom to trade without worrying
                        about my funds being held by a third party. The non-custodial
                        approach ensures my assets are always secure."
                        <span> - Sarah L.</span>
                      </h4>
                    </div>
                    <div>
                      <h4>
                        "I love the privacy features. I can trade confidently knowing my
                        information is secure and my transactions are private. The
                        interface is also very user-friendly." <span>- John M.</span>
                      </h4>
                    </div>
                    <div>
                      <h4>
                        "The quick and reliable transactions on this platform have made
                        trading a breeze. I appreciate the wide range of cryptocurrencies
                        available for trading." <span> - Emily R.</span>
                      </h4>
                    </div>
                    <div>
                      <h4>
                        "The security measures in place give me peace of mind. Knowing
                        that my assets are under my control and protected by top-notch
                        encryption makes this platform my go-to for trading."{" "}
                        <span> - David.K</span>
                      </h4>
                    </div>
                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="banner-sec-6">
          <Container>
            <Row className="banner-sec-6-head">
              <Col xxl={6} xl={7} lg={9} className="mx-auto mx-xl-0">
                <div className="buy-crypto">
                  <h2 className="head" data-aos="fade-up" data-aos-duration="2000">
                    Buy crypto Instantly with Bank Wire or Interac e-Transfer
                  </h2>
                  <div
                    className="buy-crypto-btns"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <Link className="primary-btn flex-grow-1" to={"/buy-crypto"}>
                      Buy crypto
                      <img
                        src={require("../assets/image/btn-arrow.png")}
                        className="img-fluid"
                      />
                    </Link>
                    <div className=" white-orange-btn flex-grow-1">
                      <p>Available on the</p>

                      <h6>
                        <span>
                          <img
                            src={require("../assets/image/playstore.png")}
                            className="img-fluid"
                          />
                        </span>
                        Play store
                      </h6>
                    </div>
                    <div className=" white-orange-btn flex-grow-1">
                      <p>Available on the</p>

                      <h6>
                        <span>
                          <img
                            src={require("../assets/image/applestore.png")}
                            className="img-fluid"
                          />
                        </span>
                        App store
                      </h6>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="gradient-bg">
          <section className="banner-sec-7">
            <Container>
              <h2
                className="head text-center"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Frequently Asked Questions
              </h2>
              <Accordion
                className="banner-sec-7-acc"
                defaultActiveKey="0"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span className="accordian_head_count">1</span>What is a non-custodial
                    exchange?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                      ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <span className="accordian_head_count">2</span>Lorem Ipsum is simply
                    dummy text of the printing and typesetting industry?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                      ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <span className="accordian_head_count">3</span>Lorem Ipsum is simply
                    dummy text of the printing and typesetting industry?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                      ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <span className="accordian_head_count">4</span>Lorem Ipsum is simply
                    dummy text of the printing and typesetting industry?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                      ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
          </section>

          <section className="banner-sec-8">
            <Container>
              <h2
                className="head text-center"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                The help you need, when you need it
              </h2>
              <Row className="justify-content-center">
                <Col lg={4} md={6} data-aos="zoom-in" data-aos-duration="2000">
                  <div className="news_card">
                    <div className="img_card">
                      <img
                        src={require("../assets/image/news_01.png")}
                        alt="News"
                        className="img-fluid"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                        data-aos-delay="200"
                      />
                    </div>
                    <h3>Lorem Ipsum is simply dummy text of the industry?</h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s.
                    </p>
                    <a href="/" className="news_btn">
                      Learn more{" "}
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9375 6.6875L15.75 9.5M15.75 9.5L12.9375 12.3125M15.75 9.5H2.25"
                          stroke="#9AACCD"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </Col>

                <Col lg={4} md={6} data-aos="zoom-in" data-aos-duration="2000">
                  <div className="news_card">
                    <div className="img_card">
                      <img
                        src={require("../assets/image/news_02.png")}
                        alt="News"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                        data-aos-delay="200"
                        className="img-fluid"
                      />
                    </div>
                    <h3>Lorem Ipsum is simply dummy text of the industry?</h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s.
                    </p>
                    <a href="/" className="news_btn">
                      Learn more{" "}
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="#322a5b"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9375 6.6875L15.75 9.5M15.75 9.5L12.9375 12.3125M15.75 9.5H2.25"
                          stroke="#9AACCD"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </Col>

                <Col lg={4} md={6} data-aos="zoom-in" data-aos-duration="2000">
                  <div className="news_card">
                    <div className="img_card">
                      <img
                        src={require("../assets/image/news_03.png")}
                        alt="News"
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                        data-aos-delay="200"
                        className="img-fluid"
                      />
                    </div>
                    <h3>Lorem Ipsum is simply dummy text of the industry?</h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s.
                    </p>
                    <a href="/" className="news_btn">
                      Learn more{" "}
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9375 6.6875L15.75 9.5M15.75 9.5L12.9375 12.3125M15.75 9.5H2.25"
                          stroke="#9AACCD"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <Footer />
      </main>
    </>
  )
}
export default Home
