import React from "react"
import ReactDOM from "react-dom/client"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import { store, persister } from "./redux/store"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <PersistGate persistor={persister}>
    <Provider store={store}>
      <App />
    </Provider>
  </PersistGate>,
)

reportWebVitals()
