import React, { useMemo, useState } from "react"
import Modal from "react-bootstrap/Modal"
import SumsubWebSdk from "@sumsub/websdk-react"

import { getAccessToken } from "../../api/user"

const SumSubModal = ({ show, handleClose }) => {
  const [accessToken, setAccessToken] = useState("")
  const getToken = async () => {
    try {
      const { success, result } = await getAccessToken({})
      if (success) {
        setAccessToken(result?.token?.token)
      }
    } catch (err) {
      console.log("error", err)
    }
  }

  useMemo(() => {
    getToken()
  }, [])
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="md"
      centered
      className="order_confrim_model"
    >
      <Modal.Header closeButton>
        <Modal.Title>KYC Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {accessToken ? (
          <SumsubWebSdk
            accessToken={accessToken}
            expirationHandler={() => {
              Promise.resolve(accessToken)
              handleClose()
              getToken()
            }}
            config={{
              lang: "en",
              email: "",
              phone: "",
            }}
            options={{ addViewportTag: false, adaptIframeHeight: true }}
            onMessage={(type, payload) => {
              if (
                payload &&
                payload.levelName == "basic-kyc-level" &&
                payload.reviewResult
              ) {
                if (payload.reviewResult.reviewAnswer == "GREEN") {
                  setTimeout(function () {
                    handleClose()
                  }, 3000)
                }
              } else if (
                payload &&
                payload.levelName != "basic-kyc-level" &&
                payload.reviewResult
              ) {
                if (payload.reviewResult.reviewAnswer == "GREEN") {
                  setTimeout(function () {
                    handleClose()
                  }, 3000)
                } else if (
                  payload.reviewResult.reviewAnswer == "RED" ||
                  payload.reviewResult.reviewRejectType == "FINAL"
                ) {
                  setTimeout(function () {
                    handleClose()
                  }, 3000)
                }
              }

              console.log("onMessage", type, payload)
            }}
            onError={data => console.log("onError", data)}
          />
        ) : (
          <center>
            <h3>Checking on it ,Please Wait ...</h3>
          </center>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default SumSubModal
