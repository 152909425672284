import React, { useState } from "react"
import Footer from "../component/footer"
import NavbarInner from "../component/navbar-inner"
import Sidebar from "../component/sidebar"
import { useNavigate } from "react-router-dom"

import ChangePassword from "../component/account/ChangePassword"
import SumSubModal from "../component/sellCrypto/sumsubModal"

export default function Security(props) {
  const navigate = useNavigate()
  const [passwordModal, setPasswordModal] = useState(false)

  const [sumSubModal, setSumSubModal] = useState(false)

  const handleClose = () => setPasswordModal(false)
  const handleSubClose = () => setSumSubModal(false)

  return (
    <>
      <NavbarInner />
      <div class="dashpage_wrapper">
        <Sidebar />
        <main class="main_inner_content">
          <div className="dash_section">
            <h2 className="dash_title">Security Settings</h2>
            <div className="com-border email_detail ">
              <div className="email_head">
                <img src={require("../assets/image/Frame_40117.png")} alt="Icon" />
                <div>
                  <h6>KYC</h6>
                  <p style={{ whiteSpace: "wrap" }}>
                    Used to verify account logins, withdrawals, API creation, etc.
                  </p>
                </div>
              </div>
              <div className="email_head">
                <button
                  className="border_btn border_btn1"
                  onClick={() => setSumSubModal(true)}
                >
                  <span>link</span>
                  <img
                    src={require("../assets/image/btn-arrow.png")}
                    className="img-fluid ms-2"
                  />
                </button>
              </div>
            </div>
            <div className="com-border email_detail mt-4">
              <div className="email_head">
                <img src={require("../assets/image/key.png")} alt="Icon" />
                <div>
                  <h6>Login Password</h6>
                  <p>For account login.</p>
                </div>
              </div>
              <div className="email_head">
                <button
                  className="border_btn border_btn1"
                  onClick={() => setPasswordModal(true)}
                >
                  <span>Change</span>
                  <img
                    src={require("../assets/image/btn-arrow.png")}
                    className="img-fluid ms-2"
                  />
                </button>
              </div>
            </div>
            <div className="com-border email_detail mt-4">
              <div className="email_head">
                <img src={require("../assets/image/aunthentication.png")} alt="Icon" />
                <div>
                  <h6>Two-Factor Authentication (2FA)</h6>
                  <p>Please go to two-factor authentication binding</p>
                </div>
              </div>
              <div className="email_head">
                <button
                  className="border_btn border_btn1"
                  onClick={() => navigate("/two-factor")}
                >
                  <span>Link</span>
                  <img
                    src={require("../assets/image/btn-arrow.png")}
                    className="img-fluid ms-2"
                  />
                </button>
              </div>
            </div>
          </div>
          <ChangePassword show={passwordModal} onHide={handleClose} />
          <SumSubModal show={sumSubModal} handleClose={handleSubClose} />
          <Footer />
        </main>
      </div>
    </>
  )
}
