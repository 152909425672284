import isEmpty from "is-empty"

export const isNumber = value => {
  return typeof value === "number" && !isNaN(value)
}
export const percentageCalculation = (price, percentage) => {
  price = parseFloat(price)
  percentage = parseFloat(percentage)

  if (!isEmpty(price)) {
    return price - price * (percentage / 100)
  }
  return 0
}
