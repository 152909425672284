import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

const SuccessModal = ({ show1, handleClose1 }) => {
  return (
    <Modal
      show={show1}
      onHide={handleClose1}
      backdrop="static"
      size="md"
      centered
      className="order_confrim_model"
    >
      <Modal.Header closeButton>
        <Modal.Title>Transaction complete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your order has been held for manual approval. Once confirmed as legitimate we'll
          be able to release your bitcoin to the provided deposit address - usually within
          24hrs.
        </p>
        <img
          src={require("../../assets/image/model-img.png")}
          className="img-fluid mx-auto d-block mb-4 mt-4"
        />
        <div className="model_btn">
          <Button className=" flex-fill">
            Close
            <img
              src={require("../../assets/image/arrow_orange.png")}
              className="img-fluid orange_arrow"
            />
            <img
              src={require("../../assets/image/btn-arrow.png")}
              className="img-fluid white_arrow"
            />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SuccessModal
