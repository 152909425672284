// import lib
import isEmpty from "is-empty"

export const toFixed = (item, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = parseFloat(item)
      item = item.toFixed(type)
      return parseFloat(item)
    }
    return ""
  } catch (err) {
    return ""
  }
}
export const toFixedDown = (item, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = parseFloat(item)
      let decReg = new RegExp("(\\d+\\.\\d{" + type + "})(\\d)"),
        m = item.toString().match(decReg)
      return m ? parseFloat(m[1]) : item.valueOf()
    }
    return ""
  } catch (err) {
    return ""
  }
}
