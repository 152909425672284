import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { retrieveUserProfile } from "../../api/user"

const initialState = {
  email: "",
  userId: "",
  userCode: "",
  userName: "",
  isTwoFA: false,
  isVerified: false,
  isLocked: false,
}

export const getUserProfile = createAsyncThunk("account", async () => {
  const { success, result } = await retrieveUserProfile()
  if (success) return result
  else return initialState
})

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state = { ...state, ...action.payload }
      return state
    },
    setProfilePicture: (state, action) => {
      state = { ...state, profileImage: action.payload }
      return state
    },
    resetAccount: state => {
      state = initialState
      return state
    },
  },
  extraReducers: build => {
    build.addCase(getUserProfile.fulfilled, (state, action) => {
      state = action.payload
      return state
    })
  },
})

export const { resetAccount, setAccount, setProfilePicture } = accountSlice.actions
export default accountSlice.reducer
