import React, { useEffect, useState } from "react"
import { Col, Nav, Row, Tab, Table, Pagination } from "react-bootstrap"
import { getEtransferList, getTransactionList } from "../../../api/transaction"
import { momentFormat } from "../../../lib/moment"
import isEmpty from "is-empty"
const limit = 5

const Transactions = () => {
  const [trx, setTrx] = useState([])
  const [trf, setTrf] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalEtransfer, setTotalEtransfer] = useState(0)
  const [totalTransaction, setTotalTransaction] = useState(0)

  const getEtransferHistory = async (page = 1) => {
    try {
      const { success, result } = await getEtransferList({ page, limit })
      if (success) {
        setTrf(result.data)
        setTotalEtransfer(result.count)
      }
    } catch (error) {}
  }

  const getTransactionHistory = async (page = 1) => {
    try {
      const { success, result } = await getTransactionList({ page, limit })
      if (success) {
        setTrx(result.data)
        setTotalTransaction(result.count)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getEtransferHistory(currentPage)
    getTransactionHistory(currentPage)
  }, [currentPage])

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber)
    getEtransferHistory(pageNumber)
    getTransactionHistory(pageNumber)
  }

  console.log(trf,"trf");

  return (
    <Row className="nav_history">
      <Col lg={12}>
        <h2 className="dash_title">History</h2>
        <Tab.Container id="left-tabs-example" defaultActiveKey="fundings">
          <Nav variant="pills" className="mb-3">
            <Nav.Item>
              <Nav.Link eventKey="fundings">Fundings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="orders">Orders</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="fundings">
              <div className="table_dash_card">
                {Array.isArray(trf) && trf.length > 0 ? (
                  <>
                    <div className="table_dash_card1">
                      <Table responsive className="primary_table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>After Fee</th>
                            <th>Type</th>
                            <th>Method</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(trf) && trf.length > 0 && (
                            trf.map(item => {
                              return (
                                <tr>
                                  <td>{momentFormat(item.createdAt)}</td>
                                  <td>
                                    <b>+$ {item.amount}</b>
                                  </td>
                                  <td>
                                    <b>+$ {item.afterFee}</b>
                                  </td>
                                  <td>
                                    <b>
                                      {" "}
                                      {item.paymentType == "C" ? "Deposit" : "Withdraw"}
                                    </b>
                                  </td>
                                  <td>
                                    <b> {item.method}</b>
                                  </td>
                                  <td>
                                    <span className="text_green">{item.status}</span>
                                  </td>
                                </tr>
                              )
                            })
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination>
                      {[...Array(Math.ceil(totalEtransfer / limit)).keys()].map(
                        number => (
                          <Pagination.Item
                            key={number + 1}
                            active={number + 1 === currentPage}
                            onClick={() => handlePageChange(number + 1)}
                          >
                            {number + 1}
                          </Pagination.Item>
                        ),
                      )}
                    </Pagination>
                  </>
                ):(
                  <div className="m-auto text-center">
                    <img
                      src={require("../../../assets/image/ticket.png")}
                      className="img-fluid"
                      alt="empty"
                    />
                    <p>No Records Found</p>
                  </div>
                )
                }
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="orders">
              <div className="table_dash_card">
                {Array.isArray(trx) && trx.length > 0 ? (
                  <>
                    {" "}
                    <div className="table_dash_card1">
                      <Table responsive className="primary_table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>In (CAD)</th>
                            <th>Crypto</th>
                            <th>From Address</th>
                            <th>Transaction Hash</th>
                            <th>Type</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(trx) && trx.length > 0 && (
                            trx.map(item => {
                              return (
                                <tr>
                                  <td>{momentFormat(item.createdAt)}</td>
                                  <td>
                                    <b>+{item.amount}</b>
                                  </td>
                                  <td>
                                    <b>+$ {item.actualAmount}</b>
                                  </td>
                                  <td>
                                    <b> {item.coin}</b>
                                  </td>
                                  <td>
                                    <b>
                                      {" "}
                                      {isEmpty(item.fromAddress)
                                        ? "0x10dE10DF013A53a492A821D4E48a5AB471e58Ca9"
                                        : item.fromAddress}
                                    </b>
                                  </td>
                                  <td>
                                    <b> {item.txid}</b>
                                  </td>
                                  <td>
                                    <b className="text-capitalize">
                                      {" "}
                                      {item.sideType || "-"}
                                    </b>
                                  </td>
                                  <td>
                                    <span className="text_green">{item.status}</span>
                                  </td>
                                </tr>
                              )
                            })
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination>
                      {[...Array(Math.ceil(totalTransaction / limit)).keys()].map(
                        number => (
                          <Pagination.Item
                            key={number + 1}
                            active={number + 1 === currentPage}
                            onClick={() => handlePageChange(number + 1)}
                          >
                            {number + 1}
                          </Pagination.Item>
                        ),
                      )}
                    </Pagination>
                  </>
                ) : (
                  <div className="m-auto text-center">
                    <img
                      src={require("../../../assets/image/ticket.png")}
                      className="img-fluid"
                      alt="empty"
                    />
                    <p>No Records Found</p>
                  </div>
                )}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </Row>
  )
}

export default Transactions
