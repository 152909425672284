import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import QRCode from "qrcode.react"
import { retrieveUserBalance } from "../../api/wallet"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useDispatch } from "react-redux"
import { toast } from "../../lib/toast"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { getCurrencyData } from "../../api/currency"

const AddressDetails = ({ show, handleClose, coin, marketPrice, currencyData }) => {
  const dispatch = useDispatch()

  const [assetDets, setAssetDets] = useState({ address: "" })

  const fetchUserAsset = async () => {
    const { success, result } = await retrieveUserBalance({
      type: "coinDetails",
      coin,
    })
    if (success) {
      console.log(result,"skndkdmkdm");
      setAssetDets({
        address:
          coin == "USDC" ? result.data.tokenAddress[0].address : result.data.address,
      })
    }
  }

  console.log(currencyData,"currencyData");

  useEffect(() => {
    fetchUserAsset()
  }, [coin])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
      className="order_confrim_model"
    >
      <Modal.Header closeButton>
        <Modal.Title>Crypto Deposit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          To automatically sell your crypto for canadian dollars, send any amount valued
          from $ {currencyData?.minimumDepositCad} - $ {currencyData?.maximumDepositCad} to
          the address displayed below Live Rate : $ {marketPrice} CAD Once there has been
          some network confirmations on the blockchain,your sell order will execute and
          immediately convert to CAD, minus a fee, Sell fee is {currencyData?.depositFee}%
        </p>

        {console.log(assetDets.address,"skndkdmkdm")}

        <QRCode value={assetDets.address || ""} className="mx-auto d-block" />
        <p className="text-center mt-2">{assetDets.address || ""}</p>
        <span className="">
          <CopyToClipboard
            text={assetDets.address}
            onCopy={() => {
              toast(
                {
                  message: "Copied",
                  type: "success",
                },
                dispatch,
              )
            }}
          >
            <Button className="mx-auto d-block mb-3">
              <ContentCopyIcon />
            </Button>
          </CopyToClipboard>
        </span>
        <p>
          <span className="text-danger">IMPORTANT:</span> DO NOT send any ether digital
          asset or any smart contract based token to the above ({currencyData?.coin}) auto-sell
          address as it may not be recoverable and result in permanent loss. Only selling
          of {currencyData?.name}'s native token ({currencyData?.coin}) is supported
        </p>
        <p>
          <span className="text-danger">NOTES:</span> Minimum deposit ({currencyData?.minimumDeposit + currencyData?.coin})
        </p>
        <div className="model_btn">
          <Button className=" " onClick={handleClose}>
            Close
            <img
              src={require("../../assets/image/arrow_orange.png")}
              className="img-fluid orange_arrow"
            />
            <img
              src={require("../../assets/image/btn-arrow.png")}
              className="img-fluid white_arrow"
            />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddressDetails
