import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"
import { useDispatch, useSelector } from "react-redux"

import { InputGroup, Spinner } from "react-bootstrap"
import { updateEmail } from "../../api/user"
import { toast } from "../../lib/toast"
import isEmpty from "is-empty"

const MailChangeModal = ({ show, handleClose }) => {
  const dispatch = useDispatch()
  const { account } = useSelector(state => state)

  const [error, setError] = useState({})
  const [email, setEmail] = useState()

  useEffect(() => {
    setEmail(account?.email || "")
  },[account])

  const [otp, setOtp] = useState("")

  const [loading, setLoading] = useState(false)

  const [isOtp, setIsOtp] = useState(false)

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const updateMail = async () => {
    try {
      console.log("==========");
      
      setLoading(true)
      if (isEmpty(email) && isOtp === false) {
        setLoading(false)
        setError({ email: "Email field required" })
        return
      }

      if (email && !validateEmail(email)) {
        setLoading(false)
        setError({ ...error, email: "Please enter a valid email address" })
        return
      }

      if (isEmpty(otp) && isOtp === true) {
        setLoading(false)
        setError({ ...error, otp: "Otp field required" })
        return
      }
      console.log("==========22");
      setError({})
      let data = {
        email,
        otp
      }
      const { success, message,userEmail,status } = await updateEmail({ data }, dispatch)
      if (success && !status) {
        setIsOtp(false)
        setOtp("")
        setEmail(userEmail)
        setError({})
        setMinutes(2)
        setSeconds(59)
        setLoading(false)
        setIsOtp(true)
        toast({ message: message, type: "success" }, dispatch)
      }
      else if(success && status === "finished"){
        setError({})
        setLoading(false)
        toast({ message: message, type: "success" }, dispatch)
        handleClose()
        setMinutes("")
        setSeconds("")
        setEmail("")
        setOtp("")
        setIsOtp(true)
      }
      else {
        setLoading(false)
        toast({ message, type: "error" }, dispatch)
      }
      // if (success) {
      //   setLoading(false)
      //   toast({ message, type: "success" }, dispatch)
      //   handleClose()
      // } else {
      //   setLoading(false)
      //   toast({ message, type: "error" }, dispatch)
      // }
    } catch (error) {
      setLoading(false)
      console.log(error);
      
      toast({ message: "Something went wrong", type: "error" }, dispatch)
    }
  }

  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="md"
      centered
      className="order_confrim_model"
    >
      <Modal.Header closeButton>
        <Modal.Title>Change the email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          isOtp === false ? (
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                id="email"
                name="email"
                className="primary_input"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <p className="red_text text-danger">{error.email || ""}</p>
            </Form.Group>
          ) : (
            // <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            //   <Form.Label>Enter Otp</Form.Label>
            //   <Form.Control
            //     type="text"
            //     id="otp"
            //     name="otp"
            //     className="primary_input"
            //     value={otp}
            //     onChange={e => setOtp(e.target.value)}
            //   />
            //   <p className="red_text">{error.otp || ""}</p>
            // </Form.Group>
            <div>
              <InputGroup>
                <Form.Control
                  className="primary_inp"
                  placeholder="Please enter OTP code"
                  aria-label="otp"
                  aria-describedby="basic-addon2"
                  value={otp}
                  onChange={e => setOtp(e.target.value)}
                  name="otp"
                // onKeyPress={e => {
                //   if (e.key == "Enter") handleFormSubmit(e)
                // }}
                />

                <InputGroup.Text id="basic-addon2">
                  {" "}
                  {(minutes != 0 || seconds != 0) && isOtp && (
                    <div
                      className=" w-100"
                      style={{ color: "#FF3D00" }}
                      disabled
                    >
                      {`${minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`}{" "}
                    </div>
                  )}
                </InputGroup.Text>
              </InputGroup>
              <p className="red_text text-danger">{error.otp || ""}</p>
            </div>
          )
        }

        <div className="model_btn">
          <Button
            className="flex-fill"
            // onClick={handleShow1}
            onClick={updateMail}
            onHide={handleClose}
            type="submit"
          >
            {loading ? <Spinner size="sm" /> : "Confirm"}
            <img
              src={require("../../assets/image/arrow_orange.png")}
              className="img-fluid orange_arrow"
            />
            <img
              src={require("../../assets/image/btn-arrow.png")}
              className="img-fluid white_arrow"
            />
          </Button>
        </div>
        <p>
          *Initial transaction may be held until confirmed as legitimate, all subsequent
          are instant.
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default MailChangeModal
