import React, { useState } from "react"
import BuyCryptoComponent from "../component/buyCrypto/buyCrypto"
import CryptoCoins from "../component/cryptocoins/cryptocoins"
import Footer from "../component/footer"
import NavbarInner from "../component/navbar-inner"
import Sidebar from "../component/sidebar"

import SuccessModal from "../component/buyCrypto/successModal"
import ConfirmModal from "../component/buyCrypto/confirmModal"

export default function BuyCrypto(props) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [show1, setShow1] = useState(false)
  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => setShow1(true)
  return (
    <>
      <NavbarInner />
      <div class="dashpage_wrapper">
        <Sidebar />
        <main class="main_inner_content">
          <div className="dash_section">
            <CryptoCoins type={"+"}/>
            <BuyCryptoComponent
              handleShow={handleShow}
              handleClose={handleClose}
              show={show}
              handleClose1={handleClose1}
              handleShow1={handleShow1}
            />
          </div>
          <Footer />
        </main>
      </div>
      <SuccessModal handleClose1={handleClose1} show1={show1} />
    </>
  )
}
