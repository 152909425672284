import React from "react"

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
  Navigate,
} from "react-router-dom"

import { CookiesProvider } from "react-cookie"

import Home from "./pages/home"
import Login from "./pages/login"
import Register from "./pages/register"
import ForgotPassword from "./pages/forgot-password"
import ResetPassword from "./pages/reset-password"
import Funding from "./pages/funding"
import BuyCrypto from "./pages/buy-crypto"
import SellCrypto from "./pages/sell-crypto"
import Liverate from "./pages/live-rate"
import Account from "./pages/account"
import Security from "./pages/Security"
import Blog from "./pages/blog"
import Support from "./pages/support"
import Verify from "./pages/verify"
import TwoFactor from "./pages/twoFactor"

import Toast from "./component/Toast.js"

import RouteHelper from "./helper/RouteHelper.js"
import RouteCondition from "./helper/RouteCondition.js"
import ChangePassword from "./pages/change-password.js"
import Notification from "./pages/notification.js"
import SocketContextWrapper from "./context/SocketContext.js"

const App = () => {
  // const routes = useRoutes([
  //   { path: "/*", element: <Navigate to="/home" /> },
  //   { path: "/home", element: <Home /> },
  //   { path: "/login", element: <Login /> },
  //   { path: "/register", element: <Register /> },
  //   { path: "/forgot-password", element: <ForgotPassword /> },
  //   { path: "/reset-password", element: <ResetPassword /> },
  //   { path: "/funding", element: <Funding /> },
  //   { path: "/buy-crypto", element: <BuyCrypto /> },
  //   { path: "/sell-crypto", element: <SellCrypto /> },
  //   { path: "/live-rate", element: <Liverate /> },
  //   { path: "/account", element: <Account /> },
  //   { path: "/security", element: <Security /> },
  //   { path: "/blog", element: <Blog /> },
  //   { path: "/support", element: <Support /> },
  // ]);
  const routes = useRoutes([
    {
      path: "/",
      element: <RouteCondition type={"public"} Component={Home} />,
    },
    {
      path: "/*",
      element: (
        <RouteCondition type={"auth"} Component={() => <Navigate to="/login" />} />
      ),
    },
    {
      path: "/login",
      element: <RouteCondition type={"auth"} Component={Login} />,
    },
    {
      path: "/register",
      element: <RouteCondition type={"auth"} Component={Register} />,
    },
    {
      path: "/email-verification/:token",
      element: <RouteCondition type={"auth"} Component={Verify} />,
    },
    {
      path: "/forgot-password",
      element: <RouteCondition type={"auth"} Component={ForgotPassword} />,
    },
    {
      path: "/change-password",
      element: <RouteCondition type={"public"} Component={ChangePassword} />,
    },
    {
      path: "/reset-password/:token",
      element: <RouteCondition type={"auth"} Component={ResetPassword} />,
    },
    {
      path: "/funding",
      element: <RouteCondition type={"private"} Component={Funding} />,
    },
    {
      path: "/two-factor",
      element: <RouteCondition type={"private"} Component={TwoFactor} />,
    },
    {
      path: "/buy-crypto",
      element: <RouteCondition type={"private"} Component={BuyCrypto} />,
    },
    {
      path: "/sell-crypto",
      element: <RouteCondition type={"private"} Component={SellCrypto} />,
    },
    {
      path: "/live-rate",
      element: <RouteCondition type={"private"} Component={Liverate} />,
    },
    {
      path: "/account",
      element: <RouteCondition type={"private"} Component={Account} />,
    },
    {
      path: "/security",
      element: <RouteCondition type={"private"} Component={Security} />,
    },
    {
      path: "/blog",
      element: <RouteCondition type={"private"} Component={Blog} />,
    },
    {
      path: "/support",
      element: <RouteCondition type={"private"} Component={Support} />,
    },
    {
      path: "/Notification",
      element: <RouteCondition type={"private"} Component={Notification} />,
    },
  ])
  return routes
}

const AppWrapper = () => {
  return (
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <Router basename="/">
        <SocketContextWrapper>
          <App />
          <Toast />
          <RouteHelper />
        </SocketContextWrapper>
      </Router>
    </CookiesProvider>
  )
}

export default AppWrapper
