import React from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router"
import Cookies from "js-cookie"

const RouteCondition = ({ type, Component }) => {
  const loggedIn = Cookies.get("loggedin")
  const userToken = Cookies.get("userToken")
  const isAuth = loggedIn && loggedIn.toString() == "true" && userToken

  // const auth = useSelector(state => state.auth)
  // const tokenExistence =
  //   sessionStorage.getItem("transientToken") || localStorage.getItem("transientToken")

  // let isAuth = !!(auth && auth.isAuth && tokenExistence)

  if (type === "public") {
    return <Component />
  } else if (type === "private" && isAuth) {
    return <Component />
  } else if (type === "auth" && !isAuth) {
    return <Component />
  } else if (type === "private" && !isAuth) {
    return <Navigate to={"/login"} />
  } else if (type === "auth" && isAuth) {
    return <Navigate to={"/funding"} />
  } else {
    return <Navigate to={"/login"} />
  }
}

export default RouteCondition
