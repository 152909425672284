import Cookies from "js-cookie"

export function setCookie(name, value, options) {
  Cookies.set(name, value, { expires: 365 })
}
export function getCookie(name) {
  return Cookies.get(name)
}
export function removeCookie(name, options) {
  Cookies.remove(name, options)
}

export const getAuthToken = () => {
  return getCookie("userToken")
}
export const removeAuthToken = () => {
  removeCookie("userToken")
  removeCookie("loggedin")
}
