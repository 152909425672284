import axios, { handleResponse } from "./Axios"

export const getEtransferList = async data => {
  try {
    let respData = await axios({
      method: "get",
      url: `/wallet/user-e-transfer-list`,
      params: data,
    })
    return handleResponse(respData, "success")
  } catch (err) {
    return handleResponse(err, "error")
  }
}
export const getTransactionList = async data => {
  try {
    let respData = await axios({
      method: "get",
      url: `/wallet/user-transaction-list`,
      params: data,
    })
    return handleResponse(respData, "success")
  } catch (err) {
    return handleResponse(err, "error")
  }
}
