import * as yup from "yup"

import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Col, Container, Form, Row, Spinner } from "react-bootstrap"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { apiSignIn, resendOtp } from "../../../api/auth"
import { EMAIL, PASSWORD } from "../../../constant/regex"
import { toast } from "../../../lib/toast"
import { setAccount } from "../../../redux/slices/account"
import { onSignInSuccess } from "../../../redux/slices/sessionSlice"
import { setCookie } from "../../../utils/cookie"
import InputGroup from "react-bootstrap/InputGroup"
import { getGeoInfoData, setAuthorization } from "../../../config/axios"
import browser from "browser-detect"
import logo from "../../../assets/image/logo.svg"
const initialFormValue = {
  email: "",
  password: "",
  twoFACode: "",
  otp: "",
}

const validationSchema = yup.object({
  email: yup
    .string()
    .trim("Email cannot have white spaces")
    .strict(true)
    .matches(EMAIL, { message: "Invalid email" })
    .required("Email is required"),
  password: yup.string().required("Password is required"),
})

export default function LoginForm({ visibleLoginDiv, AppLogin, DeskLogin }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [deviceInfo, setDeviceInfo] = useState({})
  const [twoFA, set2FA] = useState(false)
  const [loading, setLoader] = useState(false)
  const [emailOtp, setEmailOtp] = useState(false)
  const [otpTextBox, setOtpTextBox] = useState(false)
  const [showTwoFA, setShowTowFA] = useState(false)
  const [isMobile, setisMobile] = useState(false)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [timer, setTimer] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [resendOtpBtnStatus, setResendOtpBtnStatus] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [show, setShow] = useState({ password: false, conPassword: false })

  const [otpParams, setOtpParams] = useState({
    resendTimer: 0,
    expiryTime: 0,
    isRunTimer: false,
  })

  const {
    values,
    errors,
    status,
    touched,
    handleSubmit,
    handleChange,
    setValues,
    setErrors,
  } = useFormik({
    initialValues: initialFormValue,
    validationSchema,
    onSubmit: handleFormSubmit,
  })
  const handleReCaptcha = async () => {
    try {
      if (!executeRecaptcha) {
        toast({ message: "Recaptcha required", type: "error" }, dispatch)
        return ""
      }
      return await executeRecaptcha("login")
    } catch (err) {
      toast({ message: "Invalid recaptcha", type: "error" }, dispatch)
      return ""
    }
  }
  async function handleFormSubmit(values, { setErrors, resetForm, setStatus }) {
    try {
      console.log(values, "values")

      setLoader(true)
      // const captcha = await handleReCaptcha()
      // if (isEmpty(captcha)) {
      //   setLoader(false)
      //   toast({ message: "Invalid recaptcha", type: "error" }, dispatch)
      //   return
      // }
      let data = {
        ...values,
        loginHistory,
        otp: values.otp,
        otpTextBox,
        // reCaptcha: captcha,
      }

      let { success, status, message, token, errors, result } = await apiSignIn(data)
      console.log("status", success)
      if (success && status == "SUCCESS") {
        setAuthorization(token)
        dispatch(onSignInSuccess(token))
        setCookie("loggedin", true)
        setCookie("userToken", token)
        setErrors({})
        dispatch(setAccount(result))
        setTimeout(() => {
          setLoader(false)
          toast({ message: message, type: "success" }, dispatch)
          navigate("/funding")
        }, 2500)
      } else if (success && status == "TWO_FA") {
        setErrors({})
        setShowTowFA(true)
        setOtpTextBox(false)
        setLoader(false)
        toast({ message: message, type: "success" }, dispatch)
      } else if (success && status == "OTP_SENT") {
        setErrors({})
        setisMobile(isMobile)
        setLoader(false)
        toast({ message: message, type: "success" }, dispatch)
      } else if (success && status == "OTP") {
        setErrors({})
        setMinutes(2)
        setSeconds(59)
        setLoader(false)
        setResendOtpBtnStatus(true)
        setOtpTextBox(true)
        toast({ message: message, type: "success" }, dispatch)
      } else {
        setLoader(false)
        console.log("response.data.errors", errors)
        if (errors) {
          setErrors(errors)
        }
        if (message) toast({ message: message, type: "error" }, dispatch)
      }
    } catch (err) {
      console.log("error", err)
      setLoader(false)
      if (err?.response?.data?.errors) {
        setErrors(err.response.data.errors)
      }
      if (err?.response?.data?.message)
        toast({ message: err.response.data.message, type: "error" }, dispatch)
    }
  }
  const handleResendOtp = async e => {
    e.preventDefault()
    setLoader(true)
    try {
      let { success, status, message, token, errors, result } = await resendOtp(values)
      setLoader(false)
      if (status == "RESEND_OTP") {
        setValues({ ...values, otp: "" })
        setErrors("")
        setOtpTextBox(false)
        setMinutes(2)
        setSeconds(59)
        setOtpTextBox(true)
        setTimer(false)
        toast({ message: message, type: "success" }, dispatch)
        // setValidateError(error);
        // grecaptchaObject.reset()
        return
      }
    } catch (err) {
      setLoader(false)
      if (errors) {
        setErrors(errors)
        return
      }
      if (errors) {
        toast({ message: errors.message, type: "error" })
      }
    }
  }
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
      if (seconds == 1 && minutes == 0 && resendOtpBtnStatus) {
        setTimer(true)
      }
    }
  })

  const [loginHistory, setLoginHistory] = useState()

  const getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData()
      const browserResult = browser()
      setLoginHistory({
        countryName: result.country_name,
        countryCode: result.country_calling_code,
        ipAddress: result.ip,
        regionName: result.region,
        broswerName: browserResult.name,
        isMobile: browserResult.mobile,
        os: browserResult.os,
      })
    } catch (err) {}
  }

  useEffect(() => {
    getGeoInfo()
  }, [])

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className="auth_wrapper">
      <Row>
        <Col xl={6} lg={6} md={7} className="">
          <img
            src={require("../../../assets/image/logpage.png")}
            alt="Login"
            className="img-fluid logpage"
          />
        </Col>
        <Col xl={6} lg={6} md={5} className="mx-auto">
          <img src={logo} alt="Logo" className="logo_img" />
          <div className="auth_bg ">
            <div className="auth_header auth_header_flex">
              <h2>Sign In</h2>
              <img
                src={require("../../../assets/image/app_login_icon.png")}
                alt="Login"
              />
              {/* <img
                src={require("../../../assets/image/app_login_icon.png")}
                alt="Login"
              /> */}
              {/* <img
                  src={require("../../../assets/image/desktop_login_icon.png")}
                  alt="Login"
                  onClick={DeskLogin}
                  className={visibleLoginDiv === "DeskLoginDiv" ? "active" : ""}
                /> */}
              {/* <img
                  src={require("../../../assets/image/app_login_icon.png")}
                  alt="Login"
                  onClick={AppLogin}
                  className={visibleLoginDiv === "AppLoginDiv" ? "active" : ""}
                /> */}
            </div>
            {visibleLoginDiv === "DeskLoginDiv" && (
              <>
                <Form className="auth_form" onKeyPress={handleKeyPress}>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    className="mb-3"
                    value={values.email}
                    id="email"
                    name="email"
                    onChange={handleChange}
                  />
                  <p className="text-danger">{(touched.email && errors.email) || ""}</p>
                  <div className="position_eye">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      className="mb-3"
                      value={values.password}
                      id="password"
                      name="password"
                      onChange={handleChange}
                    />
                    <div
                      className="showpass_icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <i className="fa-regular fa-eye"></i>
                      ) : (
                        <i className="fa-regular fa-eye-slash"></i>
                      )}

                      {/* <i className="fa-regular fa-eye"></i> */}
                    </div>
                    <p className="text-danger">
                      {(touched.password && errors.password) || ""}
                    </p>
                  </div>
                  {showTwoFA && (
                    <div className="mb-4">
                      <Form.Control
                        className="primary_inp"
                        placeholder="Please enter 2FA Code"
                        aria-label="twoFACode"
                        aria-describedby="basic-addon2"
                        onChange={handleChange}
                        name="twoFACode"
                        onKeyPress={e => {
                          if (e.key == "Enter") handleFormSubmit(e)
                        }}
                      />
                      <p className="text-danger">{errors?.twoFACode}</p>
                    </div>
                  )}

                  {otpTextBox && (
                    <div className="input_group_model">
                      <InputGroup className="mb-3">
                        <Form.Control
                          className="primary_inp"
                          placeholder="Please enter OTP code"
                          aria-label="otp"
                          aria-describedby="basic-addon2"
                          value={values.otp}
                          onChange={handleChange}
                          name="otp"
                          onKeyPress={e => {
                            if (e.key == "Enter") handleFormSubmit(e)
                          }}
                        />

                        <InputGroup.Text id="basic-addon2">
                          {" "}
                          {(minutes != 0 || seconds != 0) && otpTextBox && (
                            <div className=" w-100" style={{ color: "#FF3D00" }} disabled>
                              {`${minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`}{" "}
                            </div>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <p className="text-danger">{errors?.otp || ""}</p>
                    </div>
                    // <div className="mb-4">
                    //   <Form.Control
                    //     className="primary_inp"
                    //     placeholder="Please enter OTP code"
                    //     aria-label="otp"
                    //     aria-describedby="basic-addon2"
                    //     onChange={handleChange}
                    //     name="otp"
                    //     onKeyPress={e => {
                    //       if (e.key == "Enter") handleFormSubmit(e)
                    //     }}
                    //   />
                    //   {(minutes != 0 || seconds != 0) && otpTextBox && (
                    //     <button className="primary-btn w-100" disabled>
                    //       {`${minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`}{" "}
                    //     </button>
                    //   )}

                    //   <p className="text-danger">{errors?.otp}</p>
                    // </div>
                  )}
                  <div className="text-end">
                    <Link
                      to="/forgot-password"
                      className="auth_link"
                      style={{ cursor: "pointer" }}
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <div className="mt-3">
                    {!timer ? (
                      <button
                        className="primary-btn w-100"
                        type="button"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner />
                        ) : (
                          <>
                            {" "}
                            Sign In
                            <img
                              src={require("../../../assets/image/btn-arrow.png")}
                              className="img-fluid"
                            />
                          </>
                        )}
                      </button>
                    ) : (
                      <button
                        className="primary-btn w-100"
                        type="button"
                        onClick={handleResendOtp}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner />
                        ) : (
                          <>
                            {" "}
                            Resend OTP
                            <img
                              src={require("../../../assets/image/btn-arrow.png")}
                              className="img-fluid"
                            />
                          </>
                        )}
                      </button>
                    )}
                  </div>
                  <p className="signup_link">
                    Don't have an account? <Link to="/register">Sign Up Now</Link>
                  </p>
                </Form>
              </>
            )}
            {visibleLoginDiv === "AppLoginDiv" && (
              <>
                <div className="text-center">
                  <img
                    src={require("../../../assets/image/qr_code.png")}
                    className="img-fluid"
                  />
                  <div className="scan_note">
                    <img
                      src={require("../../../assets/image/scan_icon.png")}
                      className="img-fluid"
                    />
                    <p>Open the Neco Smart app and scan the QR code to log in.</p>
                  </div>
                  <p className="signup_link">
                    Don't have an account? <Link to="/register">Sign Up Now</Link>
                  </p>
                </div>
              </>
            )}
            <div className="copyright">
              <p>Copyright © 2024 Neco Smart. All rights reserved.</p>
              <p>Privacy Policy | Terms of Service</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
