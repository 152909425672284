import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

import { calculateMarkup, getCurrencyData, getPriceCnvData } from "../../api/currency"
import AddressDetails from "./AddressDetails"

import { percentConvertPrice } from "../../lib/calculation"
import { toFixedDown } from "../../lib/roundOf"

import btcImage from "../../assets/image/btc1.png"
import ethImage from "../../assets/image/eth1.png"
import usdcImage from "../../assets/image/usdc1.png"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "../../lib/toast"
import { getActiveCurrencyList } from "../../api/user"

// const tokens = [
//   {
//     name: "BTC",
//     imgSrc: btcImage,
//     fullName: "Bitcoin",
//   },
//   {
//     name: "ETH",
//     imgSrc: ethImage,
//     fullName: "Ethereum",
//   },
//   {
//     name: "USDC",
//     imgSrc: usdcImage,
//     fullName: "USD Coin",
//   },
// ]

const initialFormValue = {
  currency: "BTC",
  quantity: "",
  amount: "", // in CAD
  address: ""
}

function SellCrypto() {
  const [isActive, setActive] = useState(false)
  const [isBuyActive, setBuyActive] = useState(false)
  const [priceCnvData, setPriceCnvData] = useState([])
  const [show, setShow] = useState(false)
  const [currentData, setCurrentData] = useState(0)
  const [currencyData, setCurrencyData] = useState({})

  const [selectedToken, setSelectedToken] = useState({
    name: "BTC",
    imgSrc: btcImage,
    fullName: "Bitcoin",
  })

  const { values, errors, touched, setValues, handleChange } = useFormik({
    initialValues: initialFormValue,
    // onSubmit: dataUpdate,
  })

  const priceCnvApiCall = async () => {
    const { success, result } = await getPriceCnvData()
    if (success) {
      setPriceCnvData(result.data)
      const data = result.data
        .find(item => item.baseSymbol == values.currency)
        .convertData.find(i => i.convertSymbol == "CAD").convertPrice
      setCurrentData(data)
    }
  }

  const toggleClass = () => {
    setActive(!isActive)
  }
  const selectToken = token => {
    setSelectedToken({
      name: token.coin,
      imgSrc: token.image,
      fullName: token.name,
    })
    setValues(prev => ({
      ...prev,
      currency: token.coin,
    }))
    setActive(false)
  }

  const BuytoggleClass = () => {
    setBuyActive(!isBuyActive)
  }

  const priceConversion = (value = "BTC") => {
    const currentCurrencyData = priceCnvData.find(item => item.baseSymbol === value)

    if (currentCurrencyData && values?.quantity) {
      const cadData = currentCurrencyData.convertData.find(
        item => item.convertSymbol === "CAD",
      )
      const decimals =
        value === "BTC"
          ? 8
          : value === "ETH"
          ? 8
          : process.env.REACT_APP_MODE !== "production"
          ? 2
          : 18
      const cadCovertPrice = cadData.convertPrice
      const fee = percentConvertPrice(
        toFixedDown(calculateMarkup(cadCovertPrice,currencyData.markupPercentage,"-")),
        currencyData.depositFee
      )
      const markupAmount = (toFixedDown(calculateMarkup(cadCovertPrice,currencyData.markupPercentage,"-")) * values.quantity) - fee
      setValues(prev => ({
        ...prev,
        amount: markupAmount,
        // amount: convertedValue,
      }))
    } else {
      setValues(prev => ({
        ...prev,
        amount: 0,
      }))
    }
  }

  const fetchCurrencyData = async () => {
    const { success, result } = await getCurrencyData({ coin: values.currency })
    if (success) {
      setCurrencyData(result)
    }
  }

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  useEffect(() => {
    priceConversion(values.currency)
  }, [values.quantity, values.currency])

  useEffect(() => {
    fetchCurrencyData()
  }, [values.currency])

  const [kycStatus, setKycStatus] = useState({ idProof: "", addressProof: "" })

  // console.log(kycStatus, "data");

  const kycData = useSelector(state => state.account.kycStatus)

  const [tokens, setTokens] = useState()

  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (tokens && tokens.length > 0) {
      setSelectedToken({
        name: tokens[index]?.coin || "",
        imgSrc: tokens[index]?.image || "",
        fullName: tokens[index]?.name || "",
      })
    }
  }, [tokens, index])

  useEffect(() => {
    priceCnvApiCall()
    if (kycData) {
      setKycStatus(kycData)
    }
    const getCurrencyData = async () => {
      const data = await getActiveCurrencyList()
      console.log(data.result, "data")
      setTokens(data.result)
    }
    getCurrencyData()
  }, [kycData])

  const dispatch = useDispatch()

  const isCad = useSelector(state => state.currency[0])

  // const [isDisabled,setIsDisabled] = useState(false)

  useEffect(() => {
    const inputElement = document.getElementById('quantity');

    const preventScrollChange = (event) => {
      event.preventDefault(); // Prevent scroll wheel from changing the input value
    };

    if (inputElement) {
      inputElement.addEventListener('wheel', preventScrollChange);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('wheel', preventScrollChange);
      }
    };
  }, []);

  return (
    <div>
      <h2 className="dash_title">Sell Crypto</h2>
      <p className="text-white">
        The Amount you receive might differ based on the market price
      </p>
      <div className="Buy_crypto_sec">
        <Row className="mb-4">
          <Col lg="6" className="border_box_head">
            <div className="border_box">
              <Row>
                <Col lg="7">
                  {" "}
                  <p>You Sell</p>
                  <div className="swap_container">
                    <div className="swap_select_token_wrap">
                      <div className="swap_select_token_inner">
                        <div
                          className={`swap_select_token ${
                            isActive ? "swap_select_token_open" : ""
                          }`}
                          onClick={toggleClass}
                        >
                          <div className="swap_select_token_option">
                            <div className="img_border">
                              <img src={selectedToken.imgSrc} alt="Crypto Coin" />
                            </div>
                            <span className="swap_token_title">{selectedToken.name}</span>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          isActive
                            ? "token_search_container token_search_container_open"
                            : "token_search_container"
                        }
                      >
                        <div className="token_search_list">
                          {Array.isArray(tokens) &&
                            tokens.map((token, index) => {
                              return token.coin !== "CAD" ? (
                                <div
                                  key={index}
                                  className="token_search_list_row"
                                  onClick={() => {
                                    setIndex(index)
                                    selectToken(token)
                                  }}
                                >
                                  <span className="token_search_list_row_img">
                                    <img src={token.image} alt="Crypto Coin" />
                                  </span>
                                  <span className="token_search_list_cur_name">
                                    {token.coin}
                                  </span>
                                  <span className="token_search_list_coin_name">
                                    {token.name}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="5" md="5" sm="5">
                  <div className="input_end">
                    <Form.Control
                      type="number"
                      id="quantity"
                      placeholder="Quantity"
                      className="form-control"
                      onChange={handleChange}
                      name="quantity"
                      value={values.quantity}
                    />
                    <p className="red_text">
                      {(touched.quantity && errors.quantity) || ""}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg="6">
            <div className="border_box">
              <Row>
                <Col lg="7" md="7" sm="7">
                  <p>You will get (after fee deduction)</p>
                  <div className="send_details">
                    <div className="img_border">
                      <img src={require("../../assets/image/flag.png")} alt="Icon" />
                    </div>
                    <h6>CAD </h6>
                  </div>
                </Col>
                <Col lg="5" md="5" sm="5">
                  <div className="input_end">
                    <Form.Group controlId="formGridAmount">
                      <Form.Control
                        type="number"
                        placeholder="Amount"
                        className="form-control"
                        name="amount"
                        disabled
                        value={values.amount}
                      />
                      <p className="red_text">
                        {(touched.amount && errors.amount) || ""}
                      </p>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {isCad && isCad.status === "active" && (
          <div className="btn-center">
            <div
              className=""
              // onClick={() => {
              //   if (kycStatus.idProof !== "approved") {
              //     // setIsDisabled(true)
              //     toast({ message: "Kyc is not verified", type: "error" }, dispatch)
              //   }
              // }}
            >
              <Link
                className="primary-btn"
                onClick={
                  // kycStatus.idProof === "approved" ? 
                  handleShow 
                  // : ""
                }
              >
                Deposit
                <img
                  src={require("../../assets/image/btn-arrow.png")}
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
        )}
      </div>
      <h2 className="dash_title mt-4">Order Limits and fees</h2>
      <div className="Order_limit">
        <p>
          For lightning fast transaction and confirmation times, we always send your
          Bitcoin ti its network with an attractive miner's fee. Fees may increase or
          decrease depending on congestion of the underlying bitcoin blockchain network
          and associated miner's fees to broadcast transaction.
        </p>
        <p>
          If your purchase is greater tha $350.00. the minimum order fee is waived you
          will still benefit from fast confirmation times
        </p>
      </div>
      <AddressDetails
        show={show}
        handleClose={handleClose}
        coin={values.currency}
        marketPrice={currentData}
        currencyData={currencyData}
      />
    </div>
  )
}

export default SellCrypto
