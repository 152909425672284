import isEmpty from "is-empty"
import axios, { handleResponse } from "./Axios"

export const getPriceCnvData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/wallet/price-cnv-data`,
    })
    return handleResponse(respData, "success")
  } catch (err) {
    return handleResponse(err, "error")
  }
}
export const getCurrencyData = async data => {
  try {
    let respData = await axios({
      method: "GET",
      url: `/wallet/get-fiat-data`,
      params: data,
    })
    return handleResponse(respData, "success")
  } catch (err) {
    return handleResponse(err, "error")
  }
}
export const getPriceData = async () => {
  try {
    let respData = await axios({
      method: "GET",
      url: `/wallet/price-data`,
    })
    return handleResponse(respData, "success")
  } catch (err) {
    return handleResponse(err, "error")
  }
}
export const cryptoPurchaseApi = async data => {
  try {
    let respData = await axios({
      method: "post",
      url: `/wallet/crypto-purchase`,
      data,
    })

    return handleResponse(respData, "success")
  } catch (err) {
    return handleResponse(err, "error")
  }
}

export const calculateMarkup = (price, percentage, type = "+") => {
  console.log(percentage,"percent",price);
  
  price = parseFloat(price);
  percentage = parseFloat(percentage);

  if (!isEmpty(price)) {
    if (type === "+") {
      return price + price * (percentage / 100);
    } else if (type === "-") {
      return price - price * (percentage / 100);
    }
  }
  return 0;
};
