import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isAuth: false,
  userId: "",
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = action.payload.auth
      state.userId = action.payload.userId
      return state
    },
    resetAuth: state => {
      state = initialState
      return state
    },
  },
})

export const { resetAuth, setAuth } = authSlice.actions
export default authSlice.reducer
