import axios from "../config/axios"
import { handleResponse } from "./Axios"

export const retrieveUserBalance = async data => {
  try {
    const response = await axios({
      url: "/wallet/user-balance",
      method: "GET",
      params: data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const manualTriggerApi = async data => {
  try {
    const response = await axios({
      url: "/user/manual-e-transfer-trigger",
      method: "GET",
      params: data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const getFiatData = async data => {
  try {
    const response = await axios({
      url: "/wallet/get-fiat-data",
      method: "GET",
      params: data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const getAllCandleData = async () => {
  try {
    const response = await axios({
      url: "/wallet/get-candle-data",
      method: "GET",
    })

    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const withdrawUserBalance = async data => {
  try {
    const response = await axios({
      url: "/wallet/withdraw",
      method: "POST",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
