import isEmpty from "is-empty"
import React, { useEffect, useState } from "react"
import { Form, Spinner } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useDispatch } from "react-redux"
import { getBankDetails, updateBankDetails } from "../../../api/user"
import {
  getFiatData,
  retrieveUserBalance,
  withdrawUserBalance,
} from "../../../api/wallet"
import { percentageCalculation } from "../../../lib/common"
import { toast } from "../../../lib/toast"
import InputGroup from "react-bootstrap/InputGroup"
const initialValues = {
  recipientName: "",
  recipientEmail: "",
  amount: "",
  afterFees: "",
  balance: 0,
  adminFee: "",
}

const WithdrawModal = ({ show, handleClose, balance, fetchBalance }) => {
  const [formValue, setFormValue] = useState(initialValues)
  const [errors, setErrors] = useState({})

  const [isEditable, setIsEditable] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const handleEditToggle = () => {
    setIsEditable(!isEditable)
  }

  const handleFormChange = e => {
    const { name, value } = e.target

    if (name === "amount") {
      let formattedValue = value

      if (value.includes(".")) {
        const parts = value.split(".")
        if (parts[1].length > 2) {
          formattedValue = parseFloat(value).toFixed(2)
        }
      }

      setFormValue(prevData => ({
        ...prevData,
        [name]: formattedValue,
      }))
    } else {
      setFormValue(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }
  }

  // const fetchUserBalance = async () => {
  //   try {
  //     const { success, result } = await retrieveUserBalance({ type: "balance" })
  //     if (success) {
  //       setFormValue(prev => ({
  //         ...prev,
  //         balance: result.data.balance,
  //       }))
  //     }
  //   } catch (error) {}
  // }

  const fetchFiatData = async () => {
    try {
      const { success, result } = await getFiatData({ coin: "CAD" })
      if (success) {
        setFormValue(prev => ({
          ...prev,
          adminFee: result.interac?.withdrawFee,
        }))
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const fetchUserSecret = async () => {
    const { success, result } = await getBankDetails()
    if (success) {
      setFormValue(prev => ({
        ...prev,
        recipientName: result.bankUserName,
        recipientEmail: result.bankUserEmail,
      }))
    }
  }

  const updateUserBankDetails = async () => {
    try {
      setLoading(true)
      const { success, message, errors } = await updateBankDetails({
        bankUserName: formValue.recipientName,
        bankUserEmail: formValue.recipientEmail,
      })
      if (success) {
        setLoading(false)
        setErrors({})
        toast({ message, type: "success" }, dispatch)
        setIsEditable(false)
      } else if (!success && errors) {
        setErrors(errors)
        setLoading(false)
      } else {
        toast({ message: "Something went wrong", type: "error" }, dispatch)
        setIsEditable(false)
        setLoading(false)
      }
    } catch (error) {
      setIsEditable(false)
      setLoading(false)
    }
  }

  const withdraw = async () => {
    try {
      setLoading(true)

      const { success, message, errors } = await withdrawUserBalance({
        amount: formValue.amount,
      })
      if (success) {
        setErrors({})
        setFormValue(prev => ({
          ...prev,
          amount: "",
          afterFees: "",
        }))
        await fetchBalance()
        setIsEditable(false)
        setLoading(false)
        toast({ message, type: "success" }, dispatch)
        handleClose()
        return
      } else if (!success && !isEmpty(errors)) {
        setErrors(errors)
        setIsEditable(false)
        setLoading(false)
        return
      } else {
        setIsEditable(false)
        setLoading(false)
        toast({ message, type: "error" }, dispatch)
      }
    } catch (error) {
      setLoading(false)
      setIsEditable(false)
      toast({ message: "Something went wrong", type: "error" }, dispatch)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (isEditable) {
      updateUserBankDetails()
    } else {
      withdraw()
    }
  }

  useEffect(() => {
    fetchUserSecret()
    // fetchUserBalance()
    fetchFiatData()
  }, [])

  useEffect(() => {
    if (formValue.amount) {

      console.log(formValue.adminFee,"fee");

      const feeExist = !isEmpty(formValue.adminFee) && formValue.adminFee != 0

      const afterFees = feeExist
        ? percentageCalculation(formValue.amount, formValue.adminFee)
        : 0

      setFormValue(prev => ({
        ...prev,
        afterFees: afterFees.toFixed(2),
      }))
    } else {
      setFormValue(prev => ({
        ...prev,
        afterFees: 0,
      }))
    }
  }, [formValue.amount])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
      className="order_confrim_model"
    >
      <Modal.Header closeButton>
        <Modal.Title>Withdraw</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Enter a dollar amount you would like to withdraw - the fee will be deduct from
          you enter
        </p>{" "}
        <p>
          Interact-e-transfer withdrawals are usually processed within 0-6 hours Once the
          funds have been sent, you will receive an email notification from us and another
          shortly from after interac,
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="recipientName">
            <Form.Label>Recipient Name</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                name="recipientName"
                placeholder="Recipient Name"
                className="primary_input  me-3"
                value={formValue.recipientName}
                disabled={!isEditable}
                onChange={handleFormChange}
              />
              <Button variant="link" onClick={handleEditToggle} hidden={isEditable}>
                Edit
              </Button>
              <p className="text-danger">{errors?.recipientName}</p>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="recipientEmail">
            <Form.Label>Recipient Email</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="email"
                name="recipientEmail"
                placeholder="Recipient Email"
                className="primary_input me-3"
                value={formValue.recipientEmail}
                disabled={!isEditable}
                onChange={handleFormChange}
              />
              <Button variant="link" onClick={handleEditToggle} hidden={isEditable}>
                Edit
              </Button>
              <p className="text-danger">{errors?.recipientEmail}</p>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="amount" hidden={isEditable}>
            <Form.Label>Amount</Form.Label>
            <div className="d-flex  align-items-center">
              <InputGroup className="mb-3 input_style input_group  me-3">
                <InputGroup.Text id="basic-addon1" className="input-symbol border_input">
                  $
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="amount"
                  value={formValue.amount}
                  placeholder="Amount"
                  className="primary_input  border_input "
                  onChange={handleFormChange}
                  style={{ paddingLeft: "20px" }}
                />
              </InputGroup>
              {/* <span className="input-symbol">$</span>
              <Form.Control
                type="text"
                name="amount"
                onChange={handleFormChange}
                value={formValue.amount}
                placeholder="Amount"
                className="primary_input  me-3"
                style={{ paddingLeft: "20px" }}
              /> */}
              <Button
                variant="link"
                className="ml-2"
                onClick={() => {
                  setFormValue(prev => ({
                    ...prev,
                    amount: balance.toFixed(2),
                  }))
                }}
              >
                Max
              </Button>
              <p className="text-danger">{errors?.amount}</p>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="afterFees" hidden={isEditable}>
            <Form.Label>After Fees</Form.Label>
            <div className="d-flex align-items-center">
              <InputGroup className="mb-3 input_style input_group  me-3">
                <InputGroup.Text id="basic-addon1" className="input-symbol border_input">
                  $
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="afterFees"
                  value={formValue.afterFees}
                  placeholder="After Fee"
                  className="primary_input  border_input "
                  style={{ paddingLeft: "20px" }}
                  disabled
                />
              </InputGroup>
              {/* <span className="input-symbol">$</span>
              <Form.Control
                type="text"
                name="afterFees"
                value={formValue.afterFees}
                placeholder="After Fee"
                className="primary_input"
                style={{ paddingLeft: "20px" }}
                disabled
              /> */}
              <p className="text-danger">{errors?.afterFees}</p>
            </div>
          </Form.Group>
          <p>Your available balance is $ {balance.toFixed(2)}</p>

          <div className="model_btn">
            <Button type="submit" className="" onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {" "}
                  {isEditable ? "Update" : "Submit"}
                  <img
                    src={require("../../../assets/image/arrow_orange.png")}
                    className="img-fluid orange_arrow"
                  />
                  <img
                    src={require("../../../assets/image/btn-arrow.png")}
                    className="img-fluid white_arrow"
                  />
                </>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default WithdrawModal
