import React, { useCallback, useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { getUserProfile } from '../redux/slices/account';
import { useLocation, useNavigate } from "react-router-dom"
import { fetchUserProfile } from "../api/user"
import { getUserWallet } from "../redux/slices/wallet"

import { FetchunReadNotice, getUnreadNotification } from "../api/notification"
import { SocketContext } from "../context/SocketContext"
import { modifySignal } from "../lib/signal"
import { toast } from "../lib/toast"
import { getCurrencyList } from "../api/user"
import isLogin from "../lib/isLogin"

const RouteHelper = () => {
  const dispatch = useDispatch()
  const socketContext = useContext(SocketContext)

  const isAuth = isLogin()

  const location = useLocation()
  const navigate = useNavigate()
  const nonAuthPoints = useCallback(
    () => [
      "login",
      "register",
      "verify",
      "forgot-password",
      "otp",
      "reset-password",
      "faq",
      "legal",
      "terms",
      "privacy",
      "/",
    ],
    [],
  )()
  useEffect(() => {
    console.log("calling");
    
    isAuth && dispatch(getUserWallet())
    isAuth && fetchUserProfile(dispatch)
    isAuth && getUnreadNotification(dispatch, false)
    isAuth && getCurrencyList(dispatch)
  }, [dispatch, isAuth])

  useEffect(() => {
    // alert("here")
    if (!isAuth && !nonAuthPoints.find(item => location.pathname.includes(item))) {
      navigate("/login")
    } else {
      socketContext.socket.on("notice", result => {
        // alert("notification")
        console.log("notice", result)
        FetchunReadNotice(dispatch, result)
        modifySignal(result.count)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, location.pathname, navigate, nonAuthPoints])
  useEffect(() => {
    if (!isAuth && !nonAuthPoints.find(item => location.pathname.includes(item))) {
      navigate("/login")
    } else {
      socketContext.socket.on("notification", result => {
        // alert("notification")
        toast({ message: result.description, type: "success" }, dispatch)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, location.pathname, navigate, nonAuthPoints])
  return <React.Fragment />
}

export default RouteHelper
