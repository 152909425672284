import React, { useRef, useEffect, useState } from "react"
import { Col, Container, Row, FloatingLabel, Form } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import Footer from "../component/footer"
import MyNavbar from "../component/navbar"
import { resetPassword } from "../api/user"
import { toast } from "../lib/toast"
import { useDispatch } from "react-redux"
import isEmpty from "is-empty"

export default function ResetPassword() {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [token] = useState(params?.token || null)
  const [loading, setLoading] = useState(false)
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  })
  const [errors, setErrors] = useState({})

  const [formValue, setFormValue] = useState({ password: "", confirmPassword: "" })
  const handleFormChange = e => {
    setErrors(prev => ({
      ...prev,
      [e.target.name]: "",
    }))
    setFormValue(prevData => {
      return { ...prevData, [e.target.name]: e.target.value }
    })
  }

  const handleSubmit = async () => {
    try {
      const { success, message, errors } = await resetPassword({
        authToken: token,
        password: formValue.password,
        confirmPassword: formValue.confirmPassword,
      })
      if (success) {
        toast({ message, type: "success" }, dispatch)
        navigate("/login")
      } else if (!success && !isEmpty(errors)) {
        setErrors(errors)
      } else {
        toast({ message, type: "error" }, dispatch)
      }
    } catch (error) {
      console.error("error", error)
      toast({ message: "Something went wrong", type: "error" }, dispatch)
    }
  }

  return (
    <>
      <MyNavbar />
      <div className="auth_wrapper">
        <Container>
          <Row>
            <Col lg={5} className="me-auto">
              <div className="auth_header">
                <h2>Reset Password</h2>
              </div>
              <Form className="auth_form">
                <FloatingLabel
                  controlId="floatingInput"
                  label="New Password"
                  className="mb-4"
                >
                  <Form.Control
                    type={passwordVisibility.password ? "text" : "password"}
                    placeholder="New Password"
                    onChange={handleFormChange}
                    id="password"
                    name="password"
                  />
                  <div
                    className="showpass_icon"
                    onClick={() =>
                      setPasswordVisibility(prev => ({
                        ...prev,
                        password: !prev.password,
                      }))
                    }
                  >
                    {passwordVisibility.password ? (
                      <i className="fa-regular fa-eye"></i>
                    ) : (
                      <i className="fa-regular fa-eye-slash"></i>
                    )}
                  </div>
                  <p className="text-danger">{errors?.password}</p>
                </FloatingLabel>

                <FloatingLabel
                  controlId="floatingInput"
                  label="Confirm Password"
                  className="mb-4"
                >
                  <Form.Control
                    type={passwordVisibility.confirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={handleFormChange}
                    id="confirmPassword"
                    name="confirmPassword"
                  />
                  <div
                    className="showpass_icon"
                    onClick={() =>
                      setPasswordVisibility(prev => ({
                        ...prev,
                        confirmPassword: !prev.confirmPassword,
                      }))
                    }
                  >
                    {passwordVisibility.confirmPassword ? (
                      <i className="fa-regular fa-eye"></i>
                    ) : (
                      <i className="fa-regular fa-eye-slash"></i>
                    )}
                  </div>
                  <p className="text-danger">{errors?.confirmPassword}</p>
                </FloatingLabel>

                <div className="mt-3">
                  <button
                    className="primary-btn w-100"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Submit
                    <img
                      src={require("../assets/image/btn-arrow.png")}
                      className="img-fluid"
                    />
                  </button>
                </div>
                <p className="signup_link">
                  Go to <Link to="/login">Log in</Link>
                </p>
              </Form>
            </Col>
            <Col lg={6} className="d-none d-lg-block">
              <img
                src={require("../assets/image/auth_img_03.png")}
                alt="Login"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}
