import React, { useCallback, useEffect, useRef, useState } from "react"
import NavbarInner from "../component/navbar-inner"
import Sidebar from "../component/sidebar"
import Footer from "../component/footer"
import { Col, Container, Row, Image, Button } from "react-bootstrap"
import { useDispatch } from "react-redux"
import {
  getReadNotification,
  getUnreadNotification,
  markAllReadNotification,
} from "../api/notification"
import { toast } from "../lib/toast"
import { format } from "timeago.js"
import isEmpty from "is-empty"

const Notification = () => {
  // const [data, setData] = useState({ result: [], count: 0 })
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({ limit: 10, page: 1 })
  const [isContinue, setContinue] = useState(true)
  const [list, setList] = useState([])
  const dispatch = useDispatch()
  const notifyContainer = useRef(null)

  // const handleUnreadParamsChange = async () => {
  //   if (loading) return false
  //   else {
  //     setParams(prev => ({ ...prev, page: prev.page + 1 }))
  //     if (data.count > params.page * params.limit) {
  //       await getUnreadNotification(params.page + 1, params.limit)
  //       setContinue(true)
  //       return true
  //     } else {
  //       setContinue(false)
  //       return false
  //     }
  //   }
  // }

  // const getNotification = useCallback(async (page = 1, limit = 10) => {
  //   try {
  //     setLoading(true)
  //     let { success, result, count } = await getNotificationHistory({ page, limit })
  //     if (success) {
  //       setLoading(false)
  //       setData(prev => ({ count: count, result: [...prev.result, ...result] }))
  //     } else {
  //       setLoading(false)
  //       setData({ count: 0, result: [] })
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     setData({ count: 0, result: [] })
  //   }
  // }, [])

  const [unreadData,setUnreadData] = useState({count:0,result:[]})

  const getUnreadData = async () => {
    try {
      setLoading(true)
      let { success, result, count } = await getUnreadNotification({})
      if (success) {
        setLoading(false)
        setUnreadData(prev => ({ count: count, result: [...prev.result, ...result] }))
      } else {
        setLoading(false)
        setUnreadData({ count: 0, result: [] })
      }
    } catch (error) {
      setLoading(false)
      setUnreadData({ count: 0, result: [] })
    }
  }

  const [readData,setReadData] = useState({result:[]})

  const getReadData = async () => {
    try {
      setLoading(true)
      let { success, result} = await getReadNotification()
      if (success) {
        setLoading(false)
        setReadData(prev => ({ result: [...prev.result, ...result] }))
      } else {
        setLoading(false)
        setReadData({result: [] })
      }
    } catch (error) {
      setLoading(false)
      setReadData({result: [] })
    }
  }

  useEffect(() => {
    getUnreadData()
    getReadData()
  }, [])

  const markAllRead = async () => {
    const { success, message } = await markAllReadNotification()
    if (success) {
      toast({ message, type: "success" }, dispatch)
      let { success, result, count } = await getUnreadNotification({})
      if (success) {
        setLoading(false)
        setUnreadData({count,result})
      } else {
        setLoading(false)
        setUnreadData({ count: 0, result: [] })
      }
      let response = await getReadNotification()
      if (response.success) {
        setLoading(false)
        setReadData({result:response.result})
      } else {
        setLoading(false)
        setReadData({result: [] })
      }
    }
    else {
      toast({ message, type: "error" }, dispatch)
    }
  };

  // console.log(readData,unreadData,"data");
  
  return (
    <>
      <NavbarInner count={unreadData.count} />
      <div class="dashpage_wrapper">
        <Sidebar />
          <main class="main_inner_content">
            <div className="dash_section">
              <div className="d-flex justify-content-between">
                <h2 className="dash_title">Notification</h2>
                <div>
                  <button className="btn border border-2 px-4" onClick={markAllRead}>Mark All Read</button>
                </div>
              </div>
              <Row>
                <Col lg={12}>
                  <div className="com-border mt-4">
                    <div>
                      <b>Unread Notifications</b>
                    </div>
                    <hr className="m-0 mt-4" />
                    <div className="notification_panel">
                      {Array.isArray(unreadData.result) && unreadData.result.length === 0 ? (
                        <div className="no-content mt-4">No Notifications</div>
                      ) : (
                        unreadData.result.map(item => (
                          <div className="notification_div" id={item._id}>
                            <div className="notify_head">
                              <strong>{item.title}</strong>
                              <h6>{item.description}</h6>
                            </div>
                            <div className="notify_sub">
                              <p>{format(item.createdAt)}</p>
                            </div>
                          </div>
                        ))
                      )
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="com-border mt-4">
                    <div>
                      <b>Read Notifications</b>
                    </div>
                    <hr className="m-0 mt-4" />
                    <div className="notification_panel">
                      {Array.isArray(readData.result) && readData.result.length === 0 ? (  
                        <div className="no-content mt-4">No Notifications</div>
                      ) : (
                        readData.result.map(item => (
                          <div className="notification_div" id={item._id}>
                            <div className="notify_head">
                              <strong>{item.title}</strong>
                              <h6>{item.description}</h6>
                            </div>
                            <div className="notify_sub">
                              <p>{format(item.createdAt)}</p>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Footer />
          </main>
      </div>
    </>
  )
}

export default Notification
