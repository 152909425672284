import React, { useRef, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import Footer from "../component/footer"
import NavbarInner from "../component/navbar-inner"
import Sidebar from "../component/sidebar"
import CryptoCoins from "../component/cryptocoins/cryptocoins"
import SellCryptoComponent from "../component/sellCrypto/sellCrypto"

export default function SellCrypto(props) {
  return (
    <>
      <NavbarInner />
      <div class="dashpage_wrapper">
        <Sidebar />
        <main class="main_inner_content">
          <div className="dash_section">
            <CryptoCoins type={"-"}/>
            <SellCryptoComponent />
          </div>
          <Footer />
        </main>
      </div>
    </>
  )
}
