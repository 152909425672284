import React from "react"
import TwoFA from "../component/security/TwoFAUpdate"
import NavbarInner from "../component/navbar-inner"
import Sidebar from "../component/sidebar"
import Footer from "../component/footer"

const TwoFactor = () => {
  return (
    <>
      <NavbarInner />
      <div class="dashpage_wrapper">
        <Sidebar />
        <main class="main_inner_content">
          <div className="dash_section">
            <TwoFA />
          </div>
          <Footer />
        </main>
      </div>
    </>
  )
}

export default TwoFactor
