let config = {
  API_HOST: "",
  API_URL: "",
  NAME: "Neco Smart",
  FRONTEND: "",
}

if (process.env.REACT_APP_MODE === "production") {
  config = {
    API_HOST: "",
    API_URL: "",
    NAME: "Neco Smart",
    FRONTEND: "",
    CRYPTO_SECRET_KEY: "xTtLdGeFAQ729WrmMBdG9Gc5ZWb8P7",
  }
} else if (process.env.REACT_APP_MODE === "demo") {
  config = {
    API_HOST: "https://necoapi.wearedev.team",
    API_URL: "https://necoapi.wearedev.team/api",
    NAME: "Neco Smart",
    FRONTEND: "https://neco-demo-frontend-2024.pages.dev",
    CRYPTO_SECRET_KEY: "xTtLdGeFAQ729WrmMBdG9Gc5ZWb8P7",
  }
} else {
  config = {
    API_HOST: "http://localhost:2053",
    API_URL: "http://localhost:2053/api",
    NAME: "Neco Smart",
    FRONTEND: "http://localhost:3000",
    CRYPTO_SECRET_KEY: "xTtLdGeFAQ729WrmMBdG9Gc5ZWb8P7",
  }
}

export default config
