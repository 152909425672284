import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import logo from "../assets/image/logo.svg"
import { toast } from "../lib/toast"
import Nav from "react-bootstrap/Nav"
import { resetAccount } from "../redux/slices/account"

import { getCookie, removeAuthToken } from "../utils/cookie"
import { removeAuthorization } from "../config/axios"

function NavbarInner({ count }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleLogout = () => {
    removeAuthorization()
    removeAuthToken()
    toast({ message: "Logout successfully", type: "success" }, dispatch)
    dispatch(resetAccount())
    navigate("/login")
  }
  return (
    <header className="navbar navbar-dark sticky-top dash_navbar">
      <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3" to="/">
        <img src={logo} alt="Logo" />
      </Link>
      <div className="inner_nav_right">
        <button
          className="navbar-toggler d-xl-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Nav.Link href="/notification" className="pos_rel">
          <span className="notification_count">{count}</span>
          <img
            src={require("../assets/image/notification.png")}
            className="img-fluid"
            alt="notification_Img"
          />
        </Nav.Link>
        <button className="primary-btn" onClick={handleLogout}>
          Logout
          <img src={require("../assets/image/btn-arrow.png")} className="img-fluid" />
        </button>
      </div>
    </header>
  )
}
export default NavbarInner
