import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import Footer from "../component/footer"
import NavbarInner from "../component/navbar-inner"
import Sidebar from "../component/sidebar"
import { getCmsContent } from "../api/user"

export default function Blog(props) {
  const [cmsData, setCmsData] = useState({ title: "", content: "" })

  const fetchCmsData = async () => {
    try {
      const { status, result } = await getCmsContent("sample_content")
      if (status) {
        setCmsData({
          title: result.title,
          content: result.content,
        })
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchCmsData()
  }, [])

  return (
    <>
      <NavbarInner />
      <div class="dashpage_wrapper">
        <Sidebar />
        <main class="main_inner_content">
          <div className="dash_section">
            <Row>
              <Col lg="8" className="mx-auto mt-5">
                {/* <div className="com-border blog_sec">
                  <h6 className="mb-4">
                    Lorem Ipsum is simply dummy text of the industry?
                  </h6>
                  <img
                    src={require("../assets/image/banner-blog.png")}
                    alt="Icon"
                    className="img-fluid mb-5"
                  />
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum passages, and
                    more recently with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum passages, and
                    more recently with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </p>
                  <h6>Where does it come from?</h6>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum passages, and
                    more recently with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </p>
                </div> */}
                <div className="com-border blog_sec">
                  <h2 className="mb-4 ">{cmsData?.title || "Title"}</h2>
                  <div dangerouslySetInnerHTML={{ __html: cmsData?.content || "" }}></div>
                </div>
              </Col>
            </Row>
          </div>
          <Footer />
        </main>
      </div>
    </>
  )
}
